import { Pagination } from "@cloudscape-design/components";
import { useState, useCallback, useEffect } from "react";

export interface PaginatedResponse<T> {
  Items: T[];
  NextToken?: string;
  MaxPages?: number;
}

interface PaginationProps<T> {
  api: (variables: any, signal?: AbortSignal) => Promise<PaginatedResponse<T>>;
  pageSize?: number;
  variables?: Record<string, any>;
  preInitialize: boolean;
}
const usePaginationHandler = <ResultType,>(
  props: PaginationProps<ResultType>
) => {
  const [initialized, setInitialized] = useState(!props.preInitialize);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pages, setPages] = useState<ResultType[][]>([]);
  const [currentPage, setCurrentPage] = useState<ResultType[]>([]);
  const [hasMorePages, setHasMorePages] = useState<boolean>(true);
  const [nextToken, setNextToken] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetPagination = useCallback(() => {
    setPages([]);
    setCurrentPageIndex(0);
    setHasMorePages(true);
    setNextToken(undefined);
    if (props.preInitialize) setInitialized(false);
  }, [props.preInitialize]);

  useEffect(() => {
    resetPagination();
  }, [ props.preInitialize]);

  useEffect(() => {
    if (!initialized) {
      loadNext()
        .then(() => setCurrentPageIndex(1))
        .then(() => setInitialized(true));
    }
  }, [initialized]);

  const loadNext = useCallback(async () => {
    if (!hasMorePages) return;
    setIsLoading(true);

    const response = await props
      .api({
        ...props.variables,
        queryParams: {
          ...(props.variables?.queryParams || {}),
          ...(props.pageSize ? { PageSize: props.pageSize } : {}),
          ...(nextToken && { NextToken: nextToken }),
        },
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
    setIsLoading(false);
    if (response == null) return;
    setNextToken(response.NextToken);
    if (response.NextToken == null) setHasMorePages(false);

    if (response.Items && response.Items.length) {
      const _pages = pages.concat([response.Items]);
      setPages(_pages);
    } else {
      setCurrentPageIndex(pages.length);
    }
    return response.Items?.length || false;
  }, [hasMorePages, props.pageSize, nextToken, pages, props.variables]);

  const onNextPageClickHandler = useCallback(
    async (e: any) => {
      if (e && e.detail && e.detail.requestedPageAvailable)
        setCurrentPageIndex(e.detail.requestedPageIndex);
      else {
        await loadNext().then(
          (success) =>
            success && setCurrentPageIndex(e.detail.requestedPageIndex)
        );
      }
    },
    []
  );

  const onPreviousPageClickHandler = useCallback(
    async (e: any) => {
      if (e.detail.requestedPageAvailable)
        setCurrentPageIndex(e.detail.requestedPageIndex);
    },
    [pages, setCurrentPageIndex]
  );

  const onPaginationChangeHandler = useCallback(
    async (e: any) => setCurrentPageIndex(e.detail.currentPageIndex),
    []
  );

  useEffect(
    () => setCurrentPage(pages[currentPageIndex - 1]),
    [pages, currentPageIndex]
  );

  const pagination = (
    <Pagination
      onChange={onPaginationChangeHandler}
      onPreviousPageClick={onPreviousPageClickHandler}
      onNextPageClick={onNextPageClickHandler}
      currentPageIndex={currentPageIndex}
      pagesCount={pages.length}
      ariaLabels={{
        nextPageLabel: "Next page",
        previousPageLabel: "Previous page",
        pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
      }}
      openEnd={hasMorePages}
    />
  );

  return {
    pagination,
    currentPage,
    isLoading,
    resetPagination,
    loadNext: onNextPageClickHandler,
  };
};

export default usePaginationHandler;
