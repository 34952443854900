import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { applyMode, Mode } from "@cloudscape-design/global-styles";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Config, { Route as ConfigRoute } from "./AppConfig";
import { GlobalProvider, useGlobal } from './common/hooks/GlobalContext';

import "./styles/App.css";
import Layout from "./common/components/layout/Layout";
import Login from "./pages/Login";
import Splash from "./common/components/layout/Splash";
import { NotificationContextProvider } from "./common/hooks/NotificationsBar";
import { User } from "./openapi-generated/lmpSchemas";
import { Auth } from "aws-amplify";

function toRouteElement(route: ConfigRoute, pathPrefix: string): JSX.Element[] {
  let routes = [];
  const path = `${pathPrefix || ""}${route.path || ""}`;
  if (route.page)
    routes.push(
      <Route key={route.path} path={path} element={<route.page />} />
    );
  for (let i = 0; route.subRoutes && i < route.subRoutes.length; i++)
    routes = routes.concat(toRouteElement(route.subRoutes[i], path));
  return routes;
}

function App() {
  const { globalUser, setGlobalUser } = useGlobal();
  const { route } = useAuthenticator((context) => [context.route]);
  // Applying theme
  useEffect(() => {
    const theme = localStorage.getItem(`${Config.appId}-theme-state`);
    if (theme === "dark") applyMode(Mode.Dark);
    else applyMode(Mode.Light);
  }, []);
  
  if(globalUser?.Role && globalUser?.Role !== "Admin") {
    Config.routing.routes = Config.routing.routes.filter(x => x.title != "Users" && x.title != "Fields")
    if(globalUser?.Role != "Power User") {
      Config.routing.routes.find(x => x.title=="Campaigns")?.subRoutes?.splice(0,1)
      if(globalUser?.Role == "NONE") {
        Config.routing.routes = [Config.routing.routes[0]]
      }
    }
  }
  const [routes] = useState(
    Config.routing.routes
      .map((route) => toRouteElement(route, ""))
      .concat([
        <Route
          key={"root"}
          path="/"
          element={<Navigate to={Config.routing.fallback} replace />}
        />,
        <Route
          key={"fallback"}
          path="*"
          element={<Navigate to={Config.routing.fallback} replace />}
        />,
      ])
  );
  
// interface RouteComponentProps {
//   user: User | undefined;
// }
// const withPrivileges = (Component: React.FC<RouteComponentProps>) => {
//   return (props: RouteComponentProps) => {
//     return <Component {...props} />;
//   };
// };

// function toRouteElement(route: ConfigRoute, pathPrefix: string, role: User | undefined): JSX.Element[] {
//   let routes = [];
//   const path = `${pathPrefix || ""}${route.path || ""}`;
//   // if (route.page)
//   //   routes.push(
//   //     <Route key={route.path} path={path} element={<route.page/>} />
//   //   );
    
//   if (route.page) {
//     const PageWithPrivileges = withPrivileges(route.page);
    
//     routes.push(
//       <Route
//         key={route.path}
//         path={path}
//         element={<PageWithPrivileges user={role} />}
//       />
//     );
//   }
//   for (let i = 0; route.subRoutes && i < route.subRoutes.length; i++)
//     routes = routes.concat(toRouteElement(route.subRoutes[i], path, role));
//   return routes;
// }

// async function fetchData() : Promise<User> {
//   const data = (await Auth.currentSession()).getIdToken().decodePayload();
//   if (data) {
//     if(data["custom:role"] !== "Admin") {
//       Config.routing.routes = Config.routing.routes.filter(x => x.title != "Users" && x.title != "Fields")
//       if(data["custom:role"] == "Backoffice") {
//         Config.routing.routes.find(x => x.title=="Campaigns")?.subRoutes?.splice(0,1)
//       }
//     }
//   }
//   return {
//     Name: data.name,
//     Surname: data.family_name,
//     Role: data["custom:role"],
//     Area: data["custom:area"],
//     LastLogin: data["custom:lastlogin"]
//   }
// }

// function buildRoutes(userRole: User) : JSX.Element[][] {
//   return Config.routing.routes
//   .map((route) => toRouteElement(route, "", userRole))
//   .concat([
//     <Route
//       key={"root"}
//       path="/"
//       element={<Navigate to={Config.routing.fallback} replace />}
//     />,
//     <Route
//       key={"fallback"}
//       path="*"
//       element={<Navigate to={Config.routing.fallback} replace />}
//     />,
//   ]) 
// }

// function App() {
//   const { route } = useAuthenticator((context) => [context.route]);

//   // Applying theme
//   useEffect(() => {
//     const theme = localStorage.getItem(`${Config.appId}-theme-state`);
//     if (theme === "dark") applyMode(Mode.Dark);
//     else applyMode(Mode.Light);
//   }, []);

//   const [user, setUser] = useState<User | null>(null);

//   useEffect(() => {
//     async function fetchUser() {
//       try {
//         const session = await Auth.currentSession();
//         const user = await fetchData();
//         setUser(user);
//       } catch (error) {
//         setUser(null);
//       }
//     }

//     if (route === "authenticated") {
//       fetchUser();
//     }
//   }, [route]);

//   if (route === "setup") {
//     return <Navigate to="/login" replace />;
//   }

//   if (!user && route === "authenticated") {
//     return null; // Or any other fallback UI while fetching user data
//   }

//   const routes = user ? buildRoutes(user) : [];
    
  return (
    <NotificationContextProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        {route === "authenticated" && (
          <Route path="/" element={<Layout />}>
            {routes}
          </Route>
        )}
        {route === "idle" && <Route path="*" element={<Splash />} />}
        {route === "setup" && (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}
        {/* <Route path="/" element={<Layout />}>
            {routes}
          </Route> */}
      </Routes>
    </NotificationContextProvider>
  );
}

export default App;
