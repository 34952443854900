import { Auth } from "aws-amplify";
import { useEffect, useRef } from "react";

export function useTraceUpdate(props: Record<string, any>) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        (ps as any)[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export function overrideWindowFetch() {
  const originalFetch = window.fetch;
  window.fetch = async (url, options) => {
    let _url;
    if (typeof (url) === "string")
      _url = url
    else
      _url = (url as Request).url

    if (!_url.includes(process.env.REACT_APP_API_ENDPOINT || ""))
      return originalFetch(url, options);

    if (!options) options = {};
    const session = await Auth.currentSession().catch((e) => null);

    if (session) {
      const headers = options?.headers || {};
      options.headers = {
        Authorization: session.getIdToken().getJwtToken(),
        access_token: session.getAccessToken().getJwtToken(),
        id_token: session.getIdToken().getJwtToken(),
        ...headers,
      };
    }

    return originalFetch(url, options);
  };
}