import {
  SpaceBetween,
  Header,
  ColumnLayout,
  Container,
} from "@cloudscape-design/components";
import { Prop } from "../../openapi-generated/lmpSchemas";

interface CampaignDetailProps {
    name: string,
    type: string,
    area: string,
    enrichFlag: string,
    retention: string,
    targetSys: string,
    dispatchMode: string,
    hashingFlag: string,
    hashingAlg: string,
    encAlg: string,
    fields: Prop[]
}

export const CampaignDetail = (
  props: CampaignDetailProps
) => {
  return (
    <>
    <div><h2>Step 1</h2></div>
    <Container>
      <ColumnLayout columns={1} variant="text-grid">
        <div >
          <Header headingTagOverride="h3">Page Content</Header>
          <h4 style={{marginBottom: 0}}>Name</h4>
          <p  style={{marginTop: 0}}>{props.name}</p>
          <h4 style={{marginBottom: 0}}>Type</h4>
          <p style={{marginTop: 0}}>{props.type}</p>
          <h4 style={{marginBottom: 0, display: props.area == "" ? "none" : "flex"}}>Market Area</h4>
          <p  style={{marginTop: 0}}>{props.area}</p>
        </div>
      </ColumnLayout>
    </Container>
    <div style={{marginTop: 25}}><h2>Step 2</h2></div>
    <Container>
      <ColumnLayout columns={1} variant="text-grid">
          <div>
            <Header headingTagOverride="h3">Data Enhancement & Dispatching</Header>
            <h4 style={{marginBottom: 0}}>Enrichment</h4>
            <p  style={{marginTop: 0}}>{props.enrichFlag == "0" ? "Disabled" : "Enabled"}</p>
            <h4 style={{marginBottom: 0}}>Retention Period</h4>
            <p style={{marginTop: 0}}>{props.retention}</p>
            <div className="columns">
              <div className="column">
                <h4 style={{marginBottom: 0, marginTop: 0}}>Target System</h4>
                <p  style={{marginTop: 0}}>{props.targetSys}</p>
              </div>
              <div className="column" style={{visibility: props.targetSys == "None" ? "hidden": "visible"}}>
                <h4 style={{marginBottom: 0, marginTop: 0}}>Sending Mode</h4>
                <p  style={{marginTop: 0}}>{props.dispatchMode}</p>
              </div>
            </div>
          </div>
          <div>
          <Header headingTagOverride="h3">Security & Encryption</Header>
            <div className="columns">
              <div className="column">
                <h4 style={{marginBottom: 0}}>Hashing</h4>
                <p  style={{marginTop: 0}}>{props.hashingFlag == "0" ? "Disabled" : "Enabled"}</p>
              </div>
              <div className="column" style={{visibility: props.hashingFlag == "0" ? "hidden": "visible"}}>
                <h4 style={{marginBottom: 0}}>Hashing Algorithm</h4>
                <p  style={{marginTop: 0}}>{props.hashingAlg}</p>
              </div>
            </div>
            <h4 style={{marginBottom: 0}}>Encryption Algorithm</h4>
            <p style={{marginTop: 0}}>{props.encAlg}</p>
        </div>
      </ColumnLayout>
    </Container>
    <div style={{marginTop: 25}}><h2>Step 3</h2></div>
    <Container>
    <Header headingTagOverride="h3">Form Fields</Header>
    <ColumnLayout columns={3} variant="text-grid">
      {props.fields.map((element, index) => (
        <>
        <div className="columns">
            <div className="column">
              <h4 style={{ marginBottom: 0 }}>{element.Name}</h4>
              <p style={{ marginTop: 0 }}>{element.Required ? "(Required)" : "(Optional)"}</p>
            </div>
            <div className="column" >
              <h4 style={{ marginBottom: 0 }}>To hash</h4>
              <p style={{ marginTop: 0 }}>{element.Hash ? "Yes": "No"}</p>
            </div>
          </div></>
      ))}
     
    </ColumnLayout>
    </Container>
    </>
  );
};

export default CampaignDetail;
