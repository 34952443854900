import CampaignsPage from "./pages/Campaigns"
import UsersPage from "./pages/Users"
import CreatePageWizard from "./pages/CampaignsWizard/CampaignsWizard"
import FieldsPage from "./pages/Fields"
import EditPage from "./pages/Edit"
import DetailsPage from "./pages/Details"
import DashboardPage from "./pages/Dashboard"
import { User } from "./openapi-generated/lmpSchemas"

export type Route = {
  title?: string
  text: string
  path: string
  page?: () => JSX.Element,
  subRoutes?: Route[],
  expandable?: boolean
  defaultExpanded?: boolean,
  hideFromSidebar?: boolean
}

const Config = {
  appId: "lmp",
  appName: "Lead Management Platform",
  sidebarTitle: "Home",
  routing: {
    fallback: "/campaigns",
    routes: [
      {
        title: "Campaigns",
        text: "Campaigns",
        path: "/campaigns",
        page: CampaignsPage,
        subRoutes: [
          {
            "title": "Create Campaign",
            text: "Create Campaign",
            path: "/create",
            page: CreatePageWizard
          }
        ]
      },
      {
        title: "Dashboard",
        text: "Dashboard",
        path: "/dashboard",
        page: DashboardPage
      },
      {
        title: "Users",
        text: "Users",
        path: "/users",
        page: UsersPage
      },
      {
        title: "Fields",
        text: "Fields",
        path: "/fields",
        page: FieldsPage
      },
      {
        title: "Edit campaign",
        text: "Edit campaign",
        path: "/edit",
        hideFromSidebar: true,
        page: EditPage
      },
      {
        title: "View details",
        text: "View details",
        path: "/details",
        hideFromSidebar: true,
        page: DetailsPage
      },
    ] as Route[] 
  }
};

export default Config;