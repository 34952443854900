import { SpaceBetween } from "@cloudscape-design/components";
import { useCallback, useEffect, useState } from "react";

import ConfirmModal from "../../common/components/elements/ConfirmModal";
import { NonCancelableEventHandler } from "@cloudscape-design/components/internal/events";
import { ValidatedSelect } from "../../common/components/elements/form-fields/ValidatedSelect";
import { fetchEditPermissions } from "../../openapi-generated/lmpComponents";

const CreateEditAccountModal: React.FC<
  React.PropsWithChildren<{
    edit: boolean;
    onClose: NonCancelableEventHandler;
    onConfirm?: NonCancelableEventHandler;
    userID: string;
    name: string;
    surname: string;
    role?: string;
    area?: string;
  }>
> = (props) => {
  const title = "Editing \"" + props.userID + "\" permissions";
  const [isLoading, setIsLoading] = useState(false);
  const [confirmEnabled, setConfirmEnabled] = useState(true);

  const role = ValidatedSelect({
    label: "Role",
    options: ["Admin", "Power User", "Backoffice"],
    placeholder: "Choose role",
    initialValue: props.role,
  });

  const area = ValidatedSelect({
    label: "Area",
    options: ["CBU", "VBI"],
    placeholder: "Choose area",
    initialValue: props.area
  });

  const onConfirmHandler = useCallback(
    (e: any) => {
      const api = fetchEditPermissions;

      setIsLoading(true);
      api({
        body: {
          UserID: props.userID,
          Name: props.name,
          Surname: props.surname,
          Role: role.value,
          Area: area.value
        },
      })
        .then(() => {
          if (props.onConfirm) props.onConfirm(e);
        })
        .finally(() => setIsLoading(false));
    },
    [props, role.value, area.value]
  );

  useEffect(() => {
    if (
      isLoading ||
      // if values are invalid
      !role.isValid || !area.isValid ||
      // if value are equal to initial values -> disable confirm button
      (props.edit &&
        props.role === role.value &&
        (props.area || "") === (area.value || ""))
    ) {
      setConfirmEnabled(false);
    } else setConfirmEnabled(true);
  }, [
    props.edit,
    props.role,
    props.area,
    role.isValid,
    area.isValid,
    area.value,
    role.value,
    isLoading,
  ]);

  return (
    <ConfirmModal
      onClose={props.onClose}
      onConfirm={onConfirmHandler}
      confirmDisabled={!confirmEnabled}
      title={title}
      loading={isLoading}
    >
      <SpaceBetween size="m">
        {role.component}
        {area.component}
      </SpaceBetween>
    </ConfirmModal>
  );
};

export default CreateEditAccountModal;
