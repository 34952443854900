// GlobalContext.tsx
import { createContext, useContext, useState, ReactNode } from 'react';
import { User } from '../../openapi-generated/lmpSchemas';

type GlobalContextType = {
  globalUser: User | undefined;
  setGlobalUser: (value: User) => void;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

type GlobalProviderProps = {
  children: ReactNode;
};

export function GlobalProvider({ children }: GlobalProviderProps) {
  const [globalUser, setGlobalUser] = useState<User>();

  return (
    <GlobalContext.Provider value={{ globalUser, setGlobalUser }}>
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobal() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }
  return context;
}
