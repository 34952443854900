import {
  SideNavigation,
  SideNavigationProps,
} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

import Config, { Route } from "../../../AppConfig";

const Sidebar = () => {
  const navigate = useNavigate();

  function toSidebarTree(
    route: Route,
    pathPrefix: string = ""
  ): SideNavigationProps.Item {
    const path = `${pathPrefix || ""}${route.path || ""}`;
    const items =
      route.subRoutes
        ?.filter((r) => !r.hideFromSidebar)
        .map((r) => toSidebarTree(r, path)) || [];
    return {
      type: items.length
        ? route.expandable
          ? "expandable-link-group"
          : "link-group"
        : "link",
      href: route.page ? path : null,
      text: route.text,
      title: route.title,
      items: items,
      defaultExpanded: route.defaultExpanded || false,
    } as unknown as SideNavigationProps.Item;
  }

  return (
    <div>
      <SideNavigation
        header={{ href: "/", text: Config.sidebarTitle }}
        items={Config.routing.routes
          .filter((r) => !r.hideFromSidebar)
          .map((r) => toSidebarTree(r))}
        onFollow={(event: any) => {
          if (event.detail.href == null) return;
          event.preventDefault();
          document.title = event.detail.title || Config.appName;
          navigate(event.detail.href);
        }}
      />
      <Footer />
    </div>
  );
};

export default Sidebar;
