import { Wizard } from "@cloudscape-design/components";
import { Navigate, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import BasicsStep from "./BasicsStep";
import FieldsStep from "./FieldsStep";
import { WizardStep } from "./WizardStep";
import ReviewStep from "./ReviewStep";
import { fetchAddCampaign } from "../../openapi-generated/lmpComponents";
import { useNotificationBar } from "../../common/hooks/NotificationsBar";
import ConfigurationsStep from "./ConfigurationsStep";
import { User } from "../../openapi-generated/lmpSchemas";
import { useGlobal } from "../../common/hooks/GlobalContext";

const CreatePageWizard = () => {
  const notificationBar = useNotificationBar();
  const navigate = useNavigate();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const { globalUser, setGlobalUser } = useGlobal();

  const [isLoading, setIsLoading] = useState(false);
  const basicsStep = BasicsStep({
    user: globalUser
  });
  const configurationsStep = ConfigurationsStep({
    area: globalUser!.Role == 'Admin' ? basicsStep.data.area : globalUser!.Area
  });
  const fieldsStep = FieldsStep({
    hashEnabled: configurationsStep.data.hashingFlag,
    targetSystem: configurationsStep.data.targetSys,
    area: globalUser!.Role == 'Admin' ? basicsStep.data.area : globalUser!.Area
    }
  );
  const reviewStep = ReviewStep({
    name: basicsStep.data.name,
    type: basicsStep.data.type,
    area: globalUser!.Role == 'Admin' ? basicsStep.data.area : "",
    enrichFlag: configurationsStep.data.enrichFlag,
    retention: configurationsStep.data.retention.label,
    targetSys: configurationsStep.data.targetSys,
    dispatchMode: configurationsStep.data.dispatchMode.label,
    hashingFlag: configurationsStep.data.hashingFlag,
    hashingAlg: configurationsStep.data.hashingAlg,
    encAlg: configurationsStep.data.encAlg,
    fields: fieldsStep.data.fields
  });

  const steps: WizardStep[] = [
    basicsStep,
    configurationsStep,
    fieldsStep,
    reviewStep,
  ];

  const createCampaignHandler = useCallback(() => {
    setIsLoading(true);
    notificationBar.clear();
    notificationBar.push({
      content: "Creating Campaign",
      dismissible: false,
      loading: true,
      type: "info",
    });
    fetchAddCampaign({
      queryParams: globalUser?.Role != 'Admin' ? { Area: globalUser?.Area } : {},
      body: {
        Name: basicsStep.data.name,
        Type: basicsStep.data.type,
        Area: globalUser!.Role == 'Admin' ? basicsStep.data.area : globalUser!.Area,
        EnrichFlag: configurationsStep.data.enrichFlag,
        Retention: configurationsStep.data.retention.value,
        TargetSys: configurationsStep.data.targetSys,
        DispatchMode: configurationsStep.data.dispatchMode.value,
        HashFlag: configurationsStep.data.hashingFlag,
        HashAlg: configurationsStep.data.hashingAlg,
        EncAlg: configurationsStep.data.encAlg,
        User: globalUser?.Name + " " + globalUser?.Surname,
        Fields: fieldsStep.data.fields,
      },
    })
      .finally(() => {
        setIsLoading(false);
        notificationBar.clear();
      })
      .then((campaign) => {
        notificationBar.push({
          content: `Campaign with ID ${campaign.CampaignId} created`,
          dismissible: true,
          type: "success",
        });
        navigate("/campaigns");
      })
      .catch((error) => {
        const message = error?.stack?.message || `Unexpected error: ${error}`;
        notificationBar.push({
          content: message,
          dismissible: true,
          type: "error",
        });
      });
  }, [
    basicsStep.data,
    configurationsStep.data,
    fieldsStep.data,
    navigate,
    notificationBar,
  ]);

  return (
    globalUser?.Role == 'Backoffice' ? <Navigate to={"/campaign"} replace /> :
    <Wizard
      className="CustomWizard"
      activeStepIndex={activeStepIndex}
      onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
      onCancel={() => navigate(`/`)}
      onSubmit={createCampaignHandler}
      isLoadingNextStep={steps[activeStepIndex].isLoading || isLoading}
      i18nStrings={{
        stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) =>
          `Step ${stepNumber} of ${stepsCount}`,
        skipToButtonLabel: (step) => `Skip to ${step.title}`,
        navigationAriaLabel: "Steps",
        cancelButton: "Cancel",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: "Create campaign",
        optional: "optional",
      }}
      steps={steps}
    />
  );
};

export default CreatePageWizard;
