import {
  Container,
  Form,
  FormField,
  SpaceBetween,
  Input,
  Autosuggest,
  AttributeEditor,
  Checkbox,
  AttributeEditorProps,
  Select,
  Alert,
  Button,
} from "@cloudscape-design/components";
import { useCallback, useEffect, useState } from "react";
import { WizardStepComponent } from "./WizardStep";
import { ValidatedInput } from "../../common/components/elements/form-fields/ValidatedInput";
import { fetchGetFields } from "../../openapi-generated/lmpComponents";
import { Field, Prop } from "../../openapi-generated/lmpSchemas";

export interface FieldsStepProps {
  hashEnabled: string
  targetSystem: string
  area: string
}

const FieldsStep: WizardStepComponent = (props: FieldsStepProps) => {
  const [items, setItems] = useState<Field[]>();
  const [fields, setFields] = useState<Prop[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  let message = "";

  const onHashChanged = useCallback((field: Prop, index: number) => {
    fields[index].Hash = !fields[index].Hash
    setFields([...fields])
  }, [fields]);

  const onRequiredChanged = useCallback((field: Prop, index: number) => {
    fields[index].Required = !fields[index].Required
    setFields([...fields])
  }, [fields]);

  const onNameChanged = useCallback((name: string, index: number) => {
    fields[index].Name = name
    setFields([...fields])

  }, [fields]);

  const definitions: AttributeEditorProps.FieldDefinition<Prop>[] = [
    {
      label: 'Name',
      control: (prop: Prop, index: number) => {
        return (
          <Select
            selectedOption={{ value: prop.Name }}
            options={items?.filter( (item) => item.Area.includes(props.area) ) .filter(x => !fields.map(x => x.Name).includes(x.Name)).map((r) => ({ value: r.Name }))}
            placeholder="Select a property"
            onChange={(event) => onNameChanged(event.detail.selectedOption.value!, index)}
            filteringType="auto"
          />
        );
      },
    },
    {
      label: 'To Hash',
      control: (prop: Prop, index: number) => <Checkbox disabled={props.hashEnabled == '0' ? true : false} checked={prop.Hash} onChange={_ => onHashChanged(prop, index)} />
    },
    {
      label: 'Required',
      control: (prop: Prop, index: number) => <Checkbox checked={prop.Required} onChange={_ => onRequiredChanged(prop, index)} />
    }
  ];

  const onAddHeaderButtonClickHandler = useCallback(() => {
    setFields([...fields, { Name: "", Hash: false, Required: false }])
  }, [fields]);

  const onRemoveHeaderButtonClickHandler = useCallback((event: any) => {
    fields.splice(event.detail.itemIndex, 1)
    setFields([...fields]);
  }, [fields]);

  const checkIfItemRemovable = useCallback((event: Prop) => {
    const buttons = document.getElementsByClassName("awsui_remove-button_n4qlp_roecl_155")
    if(buttons.length > 0){
      var b = buttons[fields.findIndex(x => x.Name == event.Name)] as HTMLButtonElement

      if(b && props.targetSystem == 'Campaign' && ["msisdn", "email", "audience_id"].includes(event.Name)){
        if (fields.filter(x => x.Name == "msisdn" || x.Name == "email" || x.Name == "audience_id").length <= 1) {
          b.classList.add("awsui_disabled_vjswe_1himx_202")
        } else {
          b.classList.remove("awsui_disabled_vjswe_1himx_202")
        }
      }
      else if (b && props.targetSystem == 'VDL') {
        if(event.Name == "msisdn")
          b.classList.add("awsui_disabled_vjswe_1himx_202")
        else b.classList.remove("awsui_disabled_vjswe_1himx_202")
      }
    }
      return true

  }, [fields]);

  const checkIfNotValid = () => {
    if(props.targetSystem == 'Campaign'){
      if (fields.filter(x => (x.Name == "msisdn" || x.Name == "email" || x.Name == "audience_id") && x.Required).length < 1){
        message = "At least one attribute among 'msisdn', 'email' and 'audience_id' is required"
        return true
      } else return false
    }
    else if (props.targetSystem == 'VDL') {
      if (fields.filter(x => x.Name == "msisdn" && x.Required).length < 1){
        message = "The 'msisdn' attribute is required"
        return true
      } else return false
    }
      else return false

  };

  useEffect(() => {
    fetchGetFields({
    }).then((response) => setItems(response.Items!))
    .then(() => {
      const _fields = [{
        Name: "msisdn", 
        Hash: false,
        Required: true
      }]
      if(props.targetSystem == "Campaign"){
        _fields.push(
        {
          Name: "email", 
          Hash: false,
          Required: true
        },
        {
          Name: "audience_id", 
          Hash: false,
          Required: true
        })
      }
      setFields(_fields)
    });
  }, [props.targetSystem]);

  useEffect(() => setIsLoading(items == null), [items]);

  const form = (
    <Form variant="embedded">
      <SpaceBetween direction="vertical" size="l">
        <div style={{ minWidth: 200 }}>
          <h2>Fields</h2>
          <AttributeEditor
            removeButtonText="Remove"
            addButtonText="Add new field"
            empty="No fields found."
            definition={definitions}
            onAddButtonClick={_ => onAddHeaderButtonClickHandler()}
            onRemoveButtonClick={onRemoveHeaderButtonClickHandler}
            items={fields}
            isItemRemovable={i => checkIfItemRemovable(i)}
          />
        </div>
      </SpaceBetween>
    </Form>
  );

  return {
    data: {
      fields: fields
    },
    title: "Fields",
    description: "Configure campaign fields",
    isOptional: false,
    isLoading: checkIfNotValid(),
    content: <>{ checkIfNotValid() && <><Alert
      statusIconAriaLabel="Info"
      header="Required field"
    >
      {message}
    </Alert><br></br></>}
    <Container>{form}</Container></>,
  };
};

export default FieldsStep;
