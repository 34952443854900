import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { NonCancelableEventHandler } from "@cloudscape-design/components/internal/events";

const ConfirmModal: React.FC<
  React.PropsWithChildren<{
    title: string;
    onClose: NonCancelableEventHandler;
    onConfirm: NonCancelableEventHandler;
    confirmDisabled?: boolean;
    loading?: boolean;
    warn?: boolean;
  }>
> = ({ onConfirm, onClose, title, children, confirmDisabled, loading, warn }) => {
  return (
    <Modal
      onDismiss={onClose}
      visible
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={confirmDisabled}
              className={warn ? "warn" : undefined}
              variant="primary"
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={title}
    >
      {children}
    </Modal>
  );
};

export default ConfirmModal;
