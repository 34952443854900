export const retentionPeriod = [
  {
    key: "24 months",
    value: "24"
  }
]

export const hashingAlgs = [
  "SHA-256"
]

export const encAlgs = [
  "AES-256"
]

export const targetSystems = [
  "VDL",
  "Campaign",
  "None"
]

export const fieldTypes = [
  "String",
  //"Date",
  "Number"
]

export const dispatchModes = [
 
  {
    key: "Every hour",
    value: "1",
  },
  {
    key: "Once a day",
    value: "24"
  },
  {
    key: "Once a week",
    value: "168"
  }
]

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
  
export const CampaignTypes = [
  "Survey",
  "Informative page",
  "Form"
]
