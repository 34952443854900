import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";
import { useState } from "react";

export interface ColumnDefinition<T> {
  id: string;
  header: string;
  visible: boolean;
  cell: (e: T) => string | number | undefined;
}

interface TablePreferencesProps {
  pageSize: 10 | 20 | 50;
  contentDensity: "comfortable" | "compact" | undefined;
  columnDefinitions: ColumnDefinition<any>[];
  onConfirm?: () => void;
}
export const useTablePreferences = (props: TablePreferencesProps) => {
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      pageSize: props.pageSize,
      contentDensity: props.contentDensity,
      visibleContent: props.columnDefinitions
        .filter((col) => col.visible)
        .map((col) => col.id),
    });

  return {
    preferences,
    collectionPreferences: (
      <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={preferences}
        pageSizePreference={{
          title: "Page size",
          options: [
            { value: 10, label: "10 resources" },
            { value: 20, label: "20 resources" },
            { value: 50, label: "50 resources" },
          ],
        }}
        onConfirm={({ detail }) => {
          setPreferences(detail);
          props.onConfirm && props.onConfirm();
        }}
        contentDensityPreference={{
          label: "Compact mode",
          description:
            "Select to display content in a denser, more compact mode",
        }}
        visibleContentPreference={{
          title: "Select visible columns",
          options: [
            {
              label: "Table columns",
              options: props.columnDefinitions.map((v) => ({
                id: v.id,
                label: v.header,
              })),
            },
          ],
        }}
      />
    ),
  };
};

export default useTablePreferences;
