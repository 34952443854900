import {
  Button,
  ButtonProps,
  Header,
  IconProps,
  SpaceBetween,
} from "@cloudscape-design/components";

interface TableHeaderAction {
  onClick: (() => Promise<void>) | (() => void);
  disabled?: boolean;
  label: string;
  iconName?: IconProps.Name;
  variant?: ButtonProps.Variant;
  loading?: boolean;
  warn?: boolean;
}

const TableHeader: React.FC<
  React.PropsWithChildren<{
    itemsCount?: number;
    actions?: TableHeaderAction[];
  }>
> = (props) => {
  return (
    <Header
      variant="awsui-h1-sticky"
      counter={props.itemsCount != null ? `(${props.itemsCount})` : undefined}
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          {props.actions?.map((action) => (
            <Button
              key={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              iconName={action.iconName}
              variant={action.variant}
              loading={action.loading}
              className={action.warn && !action.disabled ? "warn" : undefined}
            >
              {action.label}
            </Button>
          ))}
        </SpaceBetween>
      }
    >
      {props.children}
    </Header>
  );
};

export default TableHeader;
