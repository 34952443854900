import { Table, Box, Link, Icon, TextFilter, StatusIndicator, Alert, Button } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import TableHeader from "../common/components/elements/TableHeader";
import usePaginationHandler from "../common/hooks/PaginationHandler";
import {
  useTablePreferences,
  ColumnDefinition,
} from "../common/components/elements/TablePreferences";
import { DeleteCampaignRes, GetCampaignRes, User } from "../openapi-generated/lmpSchemas";
import {
  fetchDeleteCampaign,
  fetchDisableCampaign,
  fetchGetCampaign,
  fetchEnableCampaign,
  fetchLaunchCampaign,
  fetchAddCampaign
} from "../openapi-generated/lmpComponents";
import ConfirmModal from "../common/components/elements/ConfirmModal";
import { Navigate, useNavigate } from "react-router-dom";
import { useNotificationBar } from "../common/hooks/NotificationsBar";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState, TableNoMatchState } from "../common/components/elements/Empty";
import { Auth } from "aws-amplify";
import { useGlobal } from "../common/hooks/GlobalContext";

const getTextFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;


function compareStrings(value: string, query: string) {
  return value.toLowerCase().includes(query.toLowerCase());
}

const CampaignsPage = () => {
  const notificationBar = useNotificationBar();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<GetCampaignRes[]>([]);
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState(true);
  const { globalUser, setGlobalUser } = useGlobal();

const columnDefinitions: ColumnDefinition<any>[] = [
  {
    id: "campaign_id",
    header: "ID",
    visible: true,
    cell: (c) => c.CampaignId,
  },
  {
    id: "campaign_name",
    header: "Name",
    visible: true,
    cell: (c) => (
      <Button variant="link" onClick={ () => {
        navigate("/details", {state: [c]}) 
        return undefined
      }}
      >{c.Name}</Button>
    ),
  },
  {
    id: "area",
    header: "Area",
    visible: globalUser!.Role == 'Admin',
    cell: (c) => c.Area,
  },
  {
    id: "leads_coll",
    header: "Leads collected",
    visible: true,
    cell: (c) => c.LeadsColl,
  },
  {
    id: "leads_disp",
    header: "Leads dispatched",
    visible: true,
    cell: (c) => c.LeadsDisp,
  },
  {
    id: "leads_lost",
    header: "Leads lost",
    visible: true,
    cell: (c) => (
      <>
      <StatusIndicator type={c.LeadsLost == 0 ? 'success' : 'error'}> 
        {c.LeadsLost} 
      </StatusIndicator>
    </> 
    ),

  },
  {
    id: "status",
    header: "Status",
    visible: true,
    cell: c => (
    <>
      <StatusIndicator type={c.State === 'Active' ? 'success' : c.State === 'Draft' ? 'in-progress' : 'error'}> 
        {c.State} 
      </StatusIndicator>
    </> 
    )
  },
  {
    id: "user",
    header: "Last Modified by",
    visible: true,
    cell: (c) => c.User,
  },
];
  
  const queryP = globalUser?.Role != 'Admin' ? {Area : globalUser?.Area} : {}
  const { collectionPreferences, preferences } = useTablePreferences({
    pageSize: 20,
    contentDensity: "comfortable",
    columnDefinitions,
    onConfirm: () => setSelectedItems([]),
  });
  const { pagination, currentPage, isLoading, resetPagination } =
    usePaginationHandler({
      api: fetchGetCampaign,
      pageSize: preferences.pageSize || 20,
      preInitialize: true,
      variables: { queryParams: queryP }
    });
  // const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
  //   currentPage || [] ,
  //   {
  //     filtering: {
  //       empty: <TableEmptyState resourceName="Campaign" />,
  //       noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
  //       filteringFunction: (item, filteringText) =>
  //       compareStrings(item.Name, filteringText) || compareStrings(item.CampaignId, filteringText),
  //     },
  //     pagination: { pageSize: preferences.pageSize },
  //     selection: {},
  //   }
  // );
  //Removing selection on refresh
  useEffect(() => {
    setSelectedItems([]);
  }, []);

  const selectedItemDetail = selectedItems.length === 1 ;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const deleteCampaignModal = (
    <ConfirmModal
      title={selectedItems.length == 1 ? "Delete campaign" : "Delete campaigns"}
      warn={true}
      onClose={() => setDeleteModalVisible(false)}
      loading={deleteIsLoading}
      onConfirm={() => {
        setDeleteIsLoading(true);
        notificationBar.clear();
        notificationBar.push({
          content: "Deleting",
          dismissible: false,
          loading: true,
          type: "info",
        });
        fetchDeleteCampaign({ body: { CampaignIDs: selectedItems.map(x => x.CampaignId) } })
          .finally(() => {
            setDeleteIsLoading(false);
            setDeleteModalVisible(false);
            notificationBar.clear();
          })
          .then((response: DeleteCampaignRes | undefined) => {
            setSelectedItems([]);
            resetPagination();
            if(response && response["CampaignIDs"].length > 0) notificationBar.push({
              content: `The following campaign(s) are deleted: ${response["CampaignIDs"]}`,
              dismissible: true,
              type: "success",
          })
            if(response && response["NotFound"].length > 0) notificationBar.push({
              content: `These IDs were not found: ${response["NotFound"]}`,
              dismissible: true,
              type: "error",
            })
            if(response && response["TransitionForbidden"].length > 0) notificationBar.push({
              content: `These campaigns cannot be deleted because they are not in "Draft" state: ${response["TransitionForbidden"]}`,
              dismissible: true,
              type: "error",
          })
          })
          .catch((error) => {
            const message =
              error?.stack?.message || `Unexpected error: ${error}`;
            notificationBar.push({
              content: message,
              dismissible: true,
              type: "error",
            });
          });
      }}
    >
      <div>
        <p>Permanently delete <strong style={{color:"#e40501"}}>{selectedItems.map(x => x.Name).join(', ')}</strong>?</p>
        <p>This action can't be undone. All configurations will be lost.</p>  
      </div>

    </ConfirmModal>
  );

  const [disableModalVisible, setDisableModalVisible] = useState(false);
  const [disableIsLoading, setDisableIsLoading] = useState(false);
  const disableCampaignModal = (
    <ConfirmModal
      title={selectedItems.length == 1 ? "Disable campaign" : "Disable campaigns"}
      onClose={() => setDisableModalVisible(false)}
      loading={disableIsLoading}
      onConfirm={() => {
        setDisableIsLoading(true);
        notificationBar.clear();
        notificationBar.push({
          content: `Disabling`,
          dismissible: false,
          loading: true,
          type: "info",
        });
        fetchDisableCampaign({ body: { CampaignIDs: selectedItems.map(x => x.CampaignId) } })
          .finally(() => {
            setDisableIsLoading(false);
            setDisableModalVisible(false);
            notificationBar.clear();
          })
          .then((response: DeleteCampaignRes | undefined) => {
            setSelectedItems([]);
            resetPagination();
            if(response && response["CampaignIDs"].length > 0) notificationBar.push({
              content: `The following campaign(s) are disabled: ${response["CampaignIDs"]}`,
              dismissible: true,
              type: "success",
          })
          if(response && response["NotFound"].length > 0) notificationBar.push({
            content: `These CampaignIDs were not found: ${response["NotFound"]}`,
            dismissible: true,
            type: "error",
          })
          if(response && response["TransitionForbidden"].length > 0) notificationBar.push({
            content: `These campaigns cannot be disabled because they are not "Active": ${response["TransitionForbidden"]}`,
            dismissible: true,
            type: "error",
        })
          })
          .catch((error) => {
            const message =
              error?.stack?.message || `Unexpected error: ${error}`;
            notificationBar.push({
              content: message,
              dismissible: true,
              type: "error",
            });
          });
      }}
    >
      <div>
        <p>Temporarily disable <strong style={{color:"#e40501"}}>{selectedItems.map(x => x.Name).join(', ')}</strong>?</p>
        <p>Leads from this page won’t be collected anymore. </p>  
      </div>
    </ConfirmModal>
  );

  const [enableModalVisible, setEnableModalVisible] = useState(false);
  const [enableIsLoading, setEnableIsLoading] = useState(false);
  const enableCampaignModal = (
    <ConfirmModal
      title={selectedItems.length == 1 ? "Enable campaign" : "Enable campaigns"}
      onClose={() => setEnableModalVisible(false)}
      loading={enableIsLoading}
      onConfirm={() => {
        setEnableIsLoading(true);
        notificationBar.clear();
        notificationBar.push({
          content: `Enabling`,
          dismissible: false,
          loading: true,
          type: "info",
        });
        fetchEnableCampaign({ body: { CampaignIDs: selectedItems.map(x => x.CampaignId) } })
          .finally(() => {
            setEnableIsLoading(false);
            setEnableModalVisible(false);
            notificationBar.clear();
          })
          .then((response: DeleteCampaignRes | undefined) => {
            setSelectedItems([]);
            resetPagination();
            if(response && response["CampaignIDs"].length > 0) notificationBar.push({
              content: `The following campaign(s) are enabled: ${response["CampaignIDs"]}`,
              dismissible: true,
              type: "success",
          })
          if(response && response["NotFound"].length > 0) notificationBar.push({
            content: `These CampaignIDs were not found: ${response["NotFound"]}`,
            dismissible: true,
            type: "error",
          })
          if(response && response["TransitionForbidden"].length > 0) notificationBar.push({
            content: `These campaigns cannot be enabled because they are not "Disabled": ${response["TransitionForbidden"]}`,
            dismissible: true,
            type: "error",
        })
          })
          .catch((error) => {
            const message =
              error?.stack?.message || `Unexpected error: ${error}`;
            notificationBar.push({
              content: message,
              dismissible: true,
              type: "error",
            });
          });
      }}
    >
      <div>
        <p>Re-enable <strong style={{color:"#e40501"}}>{selectedItems.map(x => x.Name).join(', ')}</strong>?</p>
        <p>Leads from this page will return to be collected. </p>  
      </div>
    </ConfirmModal>
  );

  const [launchModalVisible, setLaunchModalVisible] = useState(false);
  const [launchIsLoading, setLaunchIsLoading] = useState(false);
  const launchCampaignModal = (
    <ConfirmModal
      title={selectedItems.length == 1 ? "Launch campaign" : "Launch campaigns"}
      onClose={() => setLaunchModalVisible(false)}
      loading={launchIsLoading}
      onConfirm={() => {
        setLaunchIsLoading(true);
        notificationBar.clear();
        notificationBar.push({
          content: `Launching`,
          dismissible: false,
          loading: true,
          type: "info",
        });
        fetchLaunchCampaign({ body: { CampaignIDs: selectedItems.map(x => x.CampaignId) } })
          .finally(() => {
            setLaunchIsLoading(false);
            setLaunchModalVisible(false);
            notificationBar.clear();
          })
          .then((response: DeleteCampaignRes | undefined) => {
            setSelectedItems([]);
            resetPagination();
            if(response && response["CampaignIDs"].length > 0) notificationBar.push({
              content: `The following campaigns(s) are launched: ${response["CampaignIDs"]}`,
              dismissible: true,
              type: "success",
          })
          if(response && response["NotFound"].length > 0) notificationBar.push({
            content: `These IDs were not found: ${response["NotFound"]}`,
            dismissible: true,
            type: "error",
          })
          if(response && response["TransitionForbidden"].length > 0) notificationBar.push({
            content: `These campaigns cannot be launched because they are not "Draft": ${response["TransitionForbidden"]}`,
            dismissible: true,
            type: "error",
        })
          })
          .catch((error) => {
            const message =
              error?.stack?.message || `Unexpected error: ${error}`;
            notificationBar.push({
              content: message,
              dismissible: true,
              type: "error",
            });
          });
      }}
    >
      <div>
        <p>Launch <strong style={{color:"#e40501"}}>{selectedItems.map(x => x.Name).join(', ')}</strong>?</p>
        <p>The state will become active and leads data will be collected.</p>  
      </div>
    </ConfirmModal>
  );

  const [cloneModalVisible, setCloneModalVisible] = useState(false);
  const [cloneIsLoading, setCloneIsLoading] = useState(false);
  const cloneCampaignModal = (
    <ConfirmModal
      title= "Clone campaign" 
      onClose={() => setCloneModalVisible(false)}
      loading={cloneIsLoading}
      onConfirm={() => {
        setCloneIsLoading(true);
        notificationBar.clear();
        notificationBar.push({
          content: `Cloning`,
          dismissible: false,
          loading: true,
          type: "info",
        });
        fetchAddCampaign({
          queryParams: globalUser?.Role != 'Admin' ? { Area: globalUser?.Area } : {},
          body: {
            Name: selectedItems[0].Name + " (copy)",
            Type: selectedItems[0].Type,
            Area: selectedItems[0].Area,
            EnrichFlag: selectedItems[0].EnrichFlag,
            Retention: selectedItems[0].Retention,
            TargetSys: selectedItems[0].TargetSys,
            DispatchMode: selectedItems[0].DispatchMode,
            HashFlag: selectedItems[0].HashFlag,
            HashAlg: selectedItems[0].HashAlg,
            EncAlg: selectedItems[0].EncAlg,
            User: globalUser?.Name + " " + globalUser?.Surname,
            Fields: selectedItems[0].Fields,
          },
        })
          .finally(() => {
            setCloneIsLoading(false);
            setCloneModalVisible(false);
            notificationBar.clear();
          })
          .then((campaign) => {
            setSelectedItems([]);
            resetPagination();
            notificationBar.push({
              content: `Campaign ${campaign.Name} (ID: ${campaign.CampaignId}) created`,
              dismissible: true,
              type: "success",
            });
            navigate("/campaigns");
          })
          .catch((error) => {
            const message =
              error?.stack?.message || `Unexpected error: ${error}`;
            notificationBar.push({
              content: message,
              dismissible: true,
              type: "error",
            });
          });
      }}
    >
      <div>
        <p>Clone <strong style={{color:"#e40501"}}>{selectedItems.map(x => x.Name).join(', ')}</strong>?</p>
        <p>The campaign's properties will be the same, only the ID will change.</p>  
      </div>
    </ConfirmModal>
  );

  return (
    <>
      {deleteModalVisible && deleteCampaignModal}
      {disableModalVisible && disableCampaignModal}
      {enableModalVisible && enableCampaignModal}
      {launchModalVisible && launchCampaignModal}
      {cloneModalVisible && cloneCampaignModal}
      {globalUser?.Role != 'NONE' && <Table
        variant="full-page"
        stickyHeader
        header={
          <TableHeader
            itemsCount={
            currentPage?.length
          }
            actions={globalUser?.Role != 'Backoffice' ? [
              // {
              //   disabled: selectedItems.length === 0 || selectedItems.length > 1,
              //   label: "Details",
              //   iconName: "settings",
              //   onClick: () => navigate("/details", {state: selectedItems}),
              // },
              {
                disabled: selectedItems.length === 0 || selectedItems.length > 1,
                label: "Clone",
                iconName: "copy",
                onClick: () => setCloneModalVisible(true),
              },
              {
                disabled: selectedItems.length === 0 || 
                (globalUser?.Role != 'Admin' ? 
                  selectedItems.findIndex(x => x.State != "Draft") != -1 :
                  selectedItems.findIndex(x => x.State != "Draft" && x.State != "Active"  && x.State != "Disabled") != -1), 
                label: "Delete",
                iconName: "remove",
                onClick: () => setDeleteModalVisible(true),
                warn: true,
              },
              {
                disabled: selectedItems.length === 0 || selectedItems.findIndex(x => x.State != "Active") != -1,
                label: "Disable",
                iconName: "undo",
                onClick: () => setDisableModalVisible(true),
              },
              {
                disabled: selectedItems.length === 0 || selectedItems.findIndex(x => x.State != "Disabled") != -1,
                label: "Enable",
                iconName: "redo",
                onClick: () => setEnableModalVisible(true),
              },
              {
                disabled: selectedItems.length === 0 || selectedItems.findIndex(x => x.State != "Draft") != -1,
                label: "Launch",
                iconName: "upload",
                onClick: () => setLaunchModalVisible(true),
              },
              {
                label: "Create",
                iconName: "add-plus",
                onClick: () => navigate("/campaigns/create"),
              }
            ]: [
              // {
              //   disabled: selectedItems.length === 0 || selectedItems.length > 1,
              //   label: "Details",
              //   iconName: "settings",
              //   onClick: () => navigate("/details", {state: selectedItems}),
              // }
            ]}
          >
            Campaigns
          </TableHeader>
        }
        loading={isLoading}
        contentDensity={preferences.contentDensity}
        visibleColumns={preferences.visibleContent}
        selectionType="multi"
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        // filter={
        //   <TextFilter
        //     {...filterProps}
        //     filteringAriaLabel="Filter campaigns"
        //     filteringPlaceholder="Find campaigns"
        //     filteringClearAriaLabel="Clear"
        //     countText={getTextFilterCounterText(filteredItemsCount!)}
        //   />
        // }
        trackBy="CampaignId"
        selectedItems={selectedItems}
        columnDefinitions={columnDefinitions}
        items={currentPage}
        loadingText="Loading campaigns"
        empty={
          <Box variant="p" color="text-status-info" textAlign="center">
            No campaigns to display.
          </Box>
        }
        // footer={
        //   selectedItems.length > 0 && (
        //     <Box textAlign="center">
        //       <Link onFollow={() => setSelectedItems([])}>
        //         <Icon name="close" /> deselect
        //       </Link>
        //     </Box>
        //   )
        // }
        pagination={pagination}
        preferences={collectionPreferences}
      /> } 
      {globalUser?.Role == 'NONE' &&<Alert
          statusIconAriaLabel="Warning"
          type="warning"
        >
          <h3 style={{marginBottom: 0, marginTop: 0, marginLeft: 10}}> Currently you don’t have any permission. Ask one of your administrators to give you access to the platform.</h3>
        </Alert>}
    </>
  );
};

export default CampaignsPage;
