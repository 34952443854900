import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { overrideWindowFetch } from "./common/hooks/Utils";

import "./styles/index.css";
import "./styles/base.scss";
import "./styles/footer.scss";
import "./styles/top-navigation.scss";
import "./styles/custom-theme.scss";

import App from "./App";
import { GlobalProvider } from "./common/hooks/GlobalContext";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

// Replaces window.fetch with a wrapper
// which adds the Authorization header
// on each request pointing to process.env.REACT_APP_API_ENDPOINT
overrideWindowFetch();

class CustomStorage {
  storage: Storage;
  constructor() {
      this.storage = window.sessionStorage; // Change this to sessionStorage
  }
  getItem(key: string) {
      return this.storage.getItem(key);
  }
  setItem(key: string, value: string) {
      this.storage.setItem(key, value);
  }
  removeItem(key: string) {
      this.storage.removeItem(key);
  }
  clear() {
      this.storage.clear();
  }
}

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    mandatorySignIn: true,
    storage: new CustomStorage(),
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${window.location.origin}/campaigns`,
      redirectSignOut: `${window.location.origin}/campaigns`,
      responseType: "code",
      clientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    },
  },
});

root.render(
  <BrowserRouter>
    <Authenticator.Provider>
      <GlobalProvider><App />
      </GlobalProvider>
    </Authenticator.Provider>
  </BrowserRouter>
);
