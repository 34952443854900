import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  FormField,
  RadioGroup,
  Select,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { WizardStepComponent } from "./WizardStep";
import { dispatchModes, encAlgs, hashingAlgs, retentionPeriod, targetSystems } from "../../Constants";

const ConfigurationsStep: WizardStepComponent = (props: {area: string}) => {
  const [enrichFlag, setEnrichFlag] = useState("0");
  const [hashingFlag, setHashingFlag] = useState("0");
  const [retention, setRetention] = useState<OptionDefinition>({ label:retentionPeriod[0].key, value: retentionPeriod[0].value});
  const [targetSys, setTargetSys] = useState<OptionDefinition>({ value: targetSystems[0]});
  const [encAlg, setEncAlg] = useState<OptionDefinition>({ value: encAlgs[0]});
  const [hashingAlg, setHashingAlg] = useState<OptionDefinition>({ value: hashingAlgs[0]});
  const [dispatchMode, setDispatchMode] = useState<OptionDefinition>({label: 'Real Time', value: "0"});
  const [isLoading, setIsLoading] = useState(false);


  const form1 = 
    <>
      <table className="FullWidth">
        <tbody>
          <tr>
            <td className="FullWidth">
              <SpaceBetween direction="horizontal" size="xxl">
                <div>
                  <h2>Data Enhancement & Dispatching</h2>
                  <SpaceBetween direction="horizontal" size="l">
                    <div style={{ minWidth: 300 }}>
                      <FormField label="Enhancement" stretch={true}>
                        <RadioGroup
                          items={[
                            {
                              disabled: props.area != 'VBI',
                              value: '1',
                              label: 'Enabled',
                            },
                            {
                              value: '0',
                              label: 'Disabled',
                            },
                          ]}
                          value={enrichFlag}
                          onChange={({ detail }) => setEnrichFlag(detail.value)} />
                      </FormField>
                    </div>
                  </SpaceBetween>
                  <SpaceBetween direction="horizontal" size="l">
                    <div style={{ minWidth: 300, marginTop: 25 }}>
                      <FormField label="Retention period" stretch={true}>
                        <Select
                          disabled
                          selectedOption={retention}
                          onChange={({ detail }) => setRetention(detail.selectedOption)}
                          options={retentionPeriod.map((r) => ({ label: r.key, value: r.value }))}
                          selectedAriaLabel="Retention period"
                          expandToViewport
                          virtualScroll />
                      </FormField>
                    </div>
                  </SpaceBetween>
                  <SpaceBetween direction="horizontal" size="l">
                    <div style={{ minWidth: 300, marginTop: 25 }}>
                      <FormField label="Target system" stretch={true}>
                        <Select
                          selectedOption={targetSys}
                          onChange={({ detail }) => 
                            {
                              setTargetSys(detail.selectedOption);
                              if (detail.selectedOption.value == 'Campaign') {
                                setDispatchMode({label:dispatchModes[0].key, value: dispatchModes[0].value})
                                document.getElementById("dispatch_div")!.style.display = "block"
                              }
                              else if (detail.selectedOption.value == 'VDL') {
                                setDispatchMode({label: 'Real Time', value: "0"})
                                document.getElementById("dispatch_div")!.style.display = "block"
                              }
                              else {
                                document.getElementById("dispatch_div")!.style.display = "none"
                                setDispatchMode({label: '', value: ""})
                              }
                            }
                          }
                          options={props.area != 'VBI' ? targetSystems.map((r) => ({ value: r })) : targetSystems.filter(x => x != "Campaign").map((r) => ({ value: r }))}
                          selectedAriaLabel="Target System"
                          expandToViewport
                          virtualScroll />
                      </FormField>
                    </div>
                    <div style={{ minWidth: 300, marginTop: 25, display: targetSys.value == "None" ? "none": "block"}} id="dispatch_div">
                      <FormField label="Sending mode" stretch={true}>
                        <Select
                          disabled = {targetSys.value == "VDL"}
                          selectedOption={dispatchMode}
                          onChange={({ detail }) => setDispatchMode(detail.selectedOption)}
                          options={targetSys.value =='None' ? [{label: '', value: ""}] : targetSys.value == 'VDL' ? [{label: 'Real Time', value: "0"}] : dispatchModes.map((r) => ({ label: r.key, value: r.value }))}
                          selectedAriaLabel="Dispatch Modes"
                          expandToViewport
                          virtualScroll />
                      </FormField>
                    </div>
                  </SpaceBetween>
                </div>
              </SpaceBetween>
            </td>
          </tr>
        </tbody>
      </table>
    </>;

  const form2 = 
    <>
    <table className="FullWidth">
        <tbody>
          <tr>
            <td className="FullWidth">
              <SpaceBetween direction="horizontal" size="xxl">
                <div>
                  <h2>Security and Encryption</h2>
                  <SpaceBetween direction="horizontal" size="l">
                    <div style={{ minWidth: 300 }}>
                      <FormField label="Hashing configurations" stretch={true}>
                        <RadioGroup
                          items={[
                            {
                              value: '1',
                              label: 'Enabled',
                            },
                            {
                              value: '0',
                              label: 'Disabled',
                            },
                          ]}
                          value={hashingFlag}
                          onChange={({ detail }) => setHashingFlag(detail.value)} />
                      </FormField>
                    </div>
                    <div style={{ minWidth: 300, display: hashingFlag == "0" ? "none" : "block"}}>
                      <FormField label="Hashing Algorithm" stretch={true}>
                        <Select
                          disabled
                          selectedOption={hashingAlg}
                          onChange={({ detail }) => setHashingAlg(detail.selectedOption)}
                          options={hashingAlgs.map((r) => ({ value: r }))}
                          selectedAriaLabel="Hashing Algorithm"
                          expandToViewport
                          virtualScroll />
                      </FormField>
                    </div>
                  </SpaceBetween>
                  <SpaceBetween direction="horizontal" size="l">
                    <div style={{ minWidth: 300, marginTop: 25 }}>
                      <FormField label="Encryption Algorithm" stretch={true}>
                        <Select
                          disabled
                          selectedOption={encAlg}
                          onChange={({ detail }) => setEncAlg(detail.selectedOption)}
                          options={encAlgs.map((r) => ({ value: r }))}
                          selectedAriaLabel="Ecryption Algorithm"
                          expandToViewport
                          virtualScroll />
                      </FormField>
                    </div>
                  </SpaceBetween>
                </div>
              </SpaceBetween>
            </td>
          </tr>
        </tbody>
      </table>
    </>

  const spinner = (
    <div
      className="vertical-center"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "var(--color-background-button-primary-active-vg0knj)",
      }}
    >
      <Spinner size="large" />
    </div>
  );

  return {
    title: "Configurations",
    description:
      "Configure Data Enhanchement, Dispatching and Security properties",
    isOptional: false,
    data: {
      enrichFlag: enrichFlag,
      retention: retention,
      targetSys: targetSys.value,
      dispatchMode: dispatchMode,
      hashingFlag: hashingFlag,
      hashingAlg: hashingAlg.value,
      encAlg: encAlg?.value,
    },
    isLoading,
    content: (
      <>
      <Container>
        <>
          {form1}
        </>
      </Container>
      <div style={{ margin: 50 }}></div>
      <Container>
          <>
          {form2}
          </>
        </Container>
      </>
    ),
  };
};

export default ConfigurationsStep;
