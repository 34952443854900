/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useLmpContext, LmpContext } from "./lmpContext";
import type * as Fetcher from "./lmpFetcher";
import { lmpFetch } from "./lmpFetcher";
import type * as Schemas from "./lmpSchemas";

export type AddCampaignQueryParams = {
  /**
   * @example CBU
   */
  Area?: string;
};

export type AddCampaignError = Fetcher.ErrorWrapper<undefined>;

export type AddCampaignVariables = {
  body: Schemas.CreateCampaignBody;
  queryParams?: AddCampaignQueryParams;
} & LmpContext["fetcherOptions"];

/**
 * This API is intended to create a campaign, which will be in a draft state.
 */
export const fetchAddCampaign = (
  variables: AddCampaignVariables,
  signal?: AbortSignal
) =>
  lmpFetch<
    Schemas.Campaign,
    AddCampaignError,
    Schemas.CreateCampaignBody,
    {},
    AddCampaignQueryParams,
    {}
  >({ url: "/campaigns/create", method: "post", ...variables, signal });

/**
 * This API is intended to create a campaign, which will be in a draft state.
 */
export const useAddCampaign = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Campaign,
      AddCampaignError,
      AddCampaignVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<
    Schemas.Campaign,
    AddCampaignError,
    AddCampaignVariables
  >(
    (variables: AddCampaignVariables) =>
      fetchAddCampaign({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DeleteCampaignError = Fetcher.ErrorWrapper<undefined>;

export type DeleteCampaignVariables = {
  body: Schemas.DeleteCampaignBody;
} & LmpContext["fetcherOptions"];

/**
 * This API, given an array of campaigns' ID, deletes them from the DB. Before deleting it, both the state of the campaign and the permissions of the user are checked, because only drafts can be deleted by anyone, while active campaigns can be deleted only by admins.
 */
export const fetchDeleteCampaign = (
  variables: DeleteCampaignVariables,
  signal?: AbortSignal
) =>
  lmpFetch<
    Schemas.DeleteCampaignRes,
    DeleteCampaignError,
    Schemas.DeleteCampaignBody,
    {},
    {},
    {}
  >({ url: "/campaigns/delete", method: "post", ...variables, signal });

/**
 * This API, given an array of campaigns' ID, deletes them from the DB. Before deleting it, both the state of the campaign and the permissions of the user are checked, because only drafts can be deleted by anyone, while active campaigns can be deleted only by admins.
 */
export const useDeleteCampaign = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DeleteCampaignRes,
      DeleteCampaignError,
      DeleteCampaignVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<
    Schemas.DeleteCampaignRes,
    DeleteCampaignError,
    DeleteCampaignVariables
  >(
    (variables: DeleteCampaignVariables) =>
      fetchDeleteCampaign({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DisableCampaignError = Fetcher.ErrorWrapper<undefined>;

export type DisableCampaignVariables = {
  body: Schemas.DeleteCampaignBody;
} & LmpContext["fetcherOptions"];

/**
 * This API, given an array of campaigns' IDs, disables them, meaning that the relative URL won't be reachable and leads from that campaign will stop to be collected. Before disabling it, the state of the campaign is checked, because only active campaigns can be disabled.
 */
export const fetchDisableCampaign = (
  variables: DisableCampaignVariables,
  signal?: AbortSignal
) =>
  lmpFetch<
    Schemas.DeleteCampaignRes,
    DisableCampaignError,
    Schemas.DeleteCampaignBody,
    {},
    {},
    {}
  >({ url: "/campaigns/disable", method: "patch", ...variables, signal });

/**
 * This API, given an array of campaigns' IDs, disables them, meaning that the relative URL won't be reachable and leads from that campaign will stop to be collected. Before disabling it, the state of the campaign is checked, because only active campaigns can be disabled.
 */
export const useDisableCampaign = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DeleteCampaignRes,
      DisableCampaignError,
      DisableCampaignVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<
    Schemas.DeleteCampaignRes,
    DisableCampaignError,
    DisableCampaignVariables
  >(
    (variables: DisableCampaignVariables) =>
      fetchDisableCampaign({ ...fetcherOptions, ...variables }),
    options
  );
};

export type EnableCampaignError = Fetcher.ErrorWrapper<undefined>;

export type EnableCampaignVariables = {
  body: Schemas.DeleteCampaignBody;
} & LmpContext["fetcherOptions"];

/**
 * This API, given an array of campaigns' IDs, enables them, meaning that the relative URL will return to be reachable and leads from that campaigns will resume to be collected. Before enabling it, the state of the campaign is checked, because only disabled campaigns can be enabled.
 */
export const fetchEnableCampaign = (
  variables: EnableCampaignVariables,
  signal?: AbortSignal
) =>
  lmpFetch<
    Schemas.DeleteCampaignRes,
    EnableCampaignError,
    Schemas.DeleteCampaignBody,
    {},
    {},
    {}
  >({ url: "/campaigns/enable", method: "patch", ...variables, signal });

/**
 * This API, given an array of campaigns' IDs, enables them, meaning that the relative URL will return to be reachable and leads from that campaigns will resume to be collected. Before enabling it, the state of the campaign is checked, because only disabled campaigns can be enabled.
 */
export const useEnableCampaign = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DeleteCampaignRes,
      EnableCampaignError,
      EnableCampaignVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<
    Schemas.DeleteCampaignRes,
    EnableCampaignError,
    EnableCampaignVariables
  >(
    (variables: EnableCampaignVariables) =>
      fetchEnableCampaign({ ...fetcherOptions, ...variables }),
    options
  );
};

export type LaunchCampaignError = Fetcher.ErrorWrapper<undefined>;

export type LaunchCampaignVariables = {
  body: Schemas.DeleteCampaignBody;
} & LmpContext["fetcherOptions"];

/**
 * This API, given an array of campaigns' IDs, launches them, meaning that the relative URL will begin to be reachable and leads from that campaigns will start to be collected. Before launching it, the state of the campaign is checked, because only draft campaigns can be launched.
 */
export const fetchLaunchCampaign = (
  variables: LaunchCampaignVariables,
  signal?: AbortSignal
) =>
  lmpFetch<
    Schemas.DeleteCampaignRes,
    LaunchCampaignError,
    Schemas.DeleteCampaignBody,
    {},
    {},
    {}
  >({ url: "/campaigns/launch", method: "patch", ...variables, signal });

/**
 * This API, given an array of campaigns' IDs, launches them, meaning that the relative URL will begin to be reachable and leads from that campaigns will start to be collected. Before launching it, the state of the campaign is checked, because only draft campaigns can be launched.
 */
export const useLaunchCampaign = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DeleteCampaignRes,
      LaunchCampaignError,
      LaunchCampaignVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<
    Schemas.DeleteCampaignRes,
    LaunchCampaignError,
    LaunchCampaignVariables
  >(
    (variables: LaunchCampaignVariables) =>
      fetchLaunchCampaign({ ...fetcherOptions, ...variables }),
    options
  );
};

export type EditCampaignQueryParams = {
  /**
   * @example CBU
   */
  Area?: string;
};

export type EditCampaignError = Fetcher.ErrorWrapper<undefined>;

export type EditCampaignVariables = {
  body: Schemas.EditCampaignBody;
  queryParams?: EditCampaignQueryParams;
} & LmpContext["fetcherOptions"];

/**
 * This API is intended to edit a campaign.
 */
export const fetchEditCampaign = (
  variables: EditCampaignVariables,
  signal?: AbortSignal
) =>
  lmpFetch<
    Schemas.Campaign,
    EditCampaignError,
    Schemas.EditCampaignBody,
    {},
    EditCampaignQueryParams,
    {}
  >({ url: "/campaigns/edit", method: "put", ...variables, signal });

/**
 * This API is intended to edit a campaign.
 */
export const useEditCampaign = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Campaign,
      EditCampaignError,
      EditCampaignVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<
    Schemas.Campaign,
    EditCampaignError,
    EditCampaignVariables
  >(
    (variables: EditCampaignVariables) =>
      fetchEditCampaign({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetCampaignQueryParams = {
  /**
   * @example 30
   */
  Days?: number;
  /**
   * @example CBU
   */
  Area?: string;
  /**
   * @example active
   */
  State?: string;
};

export type GetCampaignError = Fetcher.ErrorWrapper<undefined>;

export type GetCampaignVariables = {
  queryParams?: GetCampaignQueryParams;
} & LmpContext["fetcherOptions"];

/**
 * This API will return all the campaigns, which the user has permission to see, currently saved in the DB. If one of these parameters is given as input, the results will be filtered accordingly.
 */
export const fetchGetCampaign = (
  variables: GetCampaignVariables,
  signal?: AbortSignal
) =>
  lmpFetch<
    Schemas.CampaignPagination,
    GetCampaignError,
    undefined,
    {},
    GetCampaignQueryParams,
    {}
  >({ url: "/campaigns", method: "get", ...variables, signal });

/**
 * This API will return all the campaigns, which the user has permission to see, currently saved in the DB. If one of these parameters is given as input, the results will be filtered accordingly.
 */
export const useGetCampaign = <TData = Schemas.CampaignPagination>(
  variables: GetCampaignVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CampaignPagination,
      GetCampaignError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useLmpContext(options);
  return reactQuery.useQuery<
    Schemas.CampaignPagination,
    GetCampaignError,
    TData
  >(
    queryKeyFn({ path: "/campaigns", operationId: "getCampaign", variables }),
    ({ signal }) =>
      fetchGetCampaign({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type GetRolesError = Fetcher.ErrorWrapper<undefined>;

export type GetRolesVariables = LmpContext["fetcherOptions"];

/**
 * This API will return all roles.
 */
export const fetchGetRoles = (
  variables: GetRolesVariables,
  signal?: AbortSignal
) =>
  lmpFetch<Schemas.RolePagination, GetRolesError, undefined, {}, {}, {}>({
    url: "/users",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This API will return all roles.
 */
export const useGetRoles = <TData = Schemas.RolePagination>(
  variables: GetRolesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.RolePagination, GetRolesError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useLmpContext(options);
  return reactQuery.useQuery<Schemas.RolePagination, GetRolesError, TData>(
    queryKeyFn({ path: "/users", operationId: "getRoles", variables }),
    ({ signal }) => fetchGetRoles({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type EditPermissionsError = Fetcher.ErrorWrapper<undefined>;

export type EditPermissionsVariables = {
  body: Schemas.Role;
} & LmpContext["fetcherOptions"];

/**
 * This API, given the ID of a user, modifies the current permissions of that user, assigning to him the given role and area.
 */
export const fetchEditPermissions = (
  variables: EditPermissionsVariables,
  signal?: AbortSignal
) =>
  lmpFetch<undefined, EditPermissionsError, Schemas.Role, {}, {}, {}>({
    url: "/users/edit",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API, given the ID of a user, modifies the current permissions of that user, assigning to him the given role and area.
 */
export const useEditPermissions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      EditPermissionsError,
      EditPermissionsVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<
    undefined,
    EditPermissionsError,
    EditPermissionsVariables
  >(
    (variables: EditPermissionsVariables) =>
      fetchEditPermissions({ ...fetcherOptions, ...variables }),
    options
  );
};

export type AddFieldsError = Fetcher.ErrorWrapper<undefined>;

export type AddFieldsRequestBody = {
  Items?: Schemas.Field[];
};

export type AddFieldsVariables = {
  body?: AddFieldsRequestBody;
} & LmpContext["fetcherOptions"];

/**
 * This API allows the administrators to configure the fields that can be added to a campaign. It takes as input It takes as input the array containing the fields.
 */
export const fetchAddFields = (
  variables: AddFieldsVariables,
  signal?: AbortSignal
) =>
  lmpFetch<undefined, AddFieldsError, AddFieldsRequestBody, {}, {}, {}>({
    url: "/fields/add",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API allows the administrators to configure the fields that can be added to a campaign. It takes as input It takes as input the array containing the fields.
 */
export const useAddFields = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AddFieldsError,
      AddFieldsVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<undefined, AddFieldsError, AddFieldsVariables>(
    (variables: AddFieldsVariables) =>
      fetchAddFields({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GetFieldsError = Fetcher.ErrorWrapper<undefined>;

export type GetFieldsResponse = {
  Items?: Schemas.Field[];
};

export type GetFieldsVariables = LmpContext["fetcherOptions"];

/**
 * This API returns all the possible fields that can be chosen when configuring a campaign.
 */
export const fetchGetFields = (
  variables: GetFieldsVariables,
  signal?: AbortSignal
) =>
  lmpFetch<GetFieldsResponse, GetFieldsError, undefined, {}, {}, {}>({
    url: "/fields",
    method: "get",
    ...variables,
    signal,
  });

/**
 * This API returns all the possible fields that can be chosen when configuring a campaign.
 */
export const useGetFields = <TData = GetFieldsResponse>(
  variables: GetFieldsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFieldsResponse, GetFieldsError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useLmpContext(options);
  return reactQuery.useQuery<GetFieldsResponse, GetFieldsError, TData>(
    queryKeyFn({ path: "/fields", operationId: "getFields", variables }),
    ({ signal }) => fetchGetFields({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type GetLeadsByMonthQueryParams = {
  /**
   * @example CBU
   */
  Area?: string;
};

export type GetLeadsByMonthError = Fetcher.ErrorWrapper<undefined>;

export type GetLeadsByMonthResponse = Schemas.LeadsByMonth[];

export type GetLeadsByMonthVariables = {
  queryParams?: GetLeadsByMonthQueryParams;
} & LmpContext["fetcherOptions"];

/**
 * This API returns the number of leads (collected and dispatched) for each month.
 */
export const fetchGetLeadsByMonth = (
  variables: GetLeadsByMonthVariables,
  signal?: AbortSignal
) =>
  lmpFetch<
    GetLeadsByMonthResponse,
    GetLeadsByMonthError,
    undefined,
    {},
    GetLeadsByMonthQueryParams,
    {}
  >({ url: "/leadsByMonth", method: "get", ...variables, signal });

/**
 * This API returns the number of leads (collected and dispatched) for each month.
 */
export const useGetLeadsByMonth = <TData = GetLeadsByMonthResponse>(
  variables: GetLeadsByMonthVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetLeadsByMonthResponse,
      GetLeadsByMonthError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useLmpContext(options);
  return reactQuery.useQuery<
    GetLeadsByMonthResponse,
    GetLeadsByMonthError,
    TData
  >(
    queryKeyFn({
      path: "/leadsByMonth",
      operationId: "getLeadsByMonth",
      variables,
    }),
    ({ signal }) =>
      fetchGetLeadsByMonth({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions,
    }
  );
};

export type LogoutError = Fetcher.ErrorWrapper<undefined>;

export type LogoutRequestBody = {
  /**
   * @example mariorossi@email.it
   */
  Username?: string;
};

export type LogoutVariables = {
  body?: LogoutRequestBody;
} & LmpContext["fetcherOptions"];

/**
 * This API logs the user's sign out.
 */
export const fetchLogout = (variables: LogoutVariables, signal?: AbortSignal) =>
  lmpFetch<undefined, LogoutError, LogoutRequestBody, {}, {}, {}>({
    url: "/logout",
    method: "post",
    ...variables,
    signal,
  });

/**
 * This API logs the user's sign out.
 */
export const useLogout = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, LogoutError, LogoutVariables>,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useLmpContext();
  return reactQuery.useMutation<undefined, LogoutError, LogoutVariables>(
    (variables: LogoutVariables) =>
      fetchLogout({ ...fetcherOptions, ...variables }),
    options
  );
};

export type QueryOperation =
  | {
      path: "/campaigns";
      operationId: "getCampaign";
      variables: GetCampaignVariables;
    }
  | {
      path: "/users";
      operationId: "getRoles";
      variables: GetRolesVariables;
    }
  | {
      path: "/fields";
      operationId: "getFields";
      variables: GetFieldsVariables;
    }
  | {
      path: "/leadsByMonth";
      operationId: "getLeadsByMonth";
      variables: GetLeadsByMonthVariables;
    };
