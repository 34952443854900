import {
    Container,
    Form,
    SpaceBetween,
    Input,
    AttributeEditor,
    AttributeEditorProps,
    Multiselect,
    Header,
    Button,
    Alert,
    FormField,
    Select,
    RadioGroup,
    Checkbox,
  } from "@cloudscape-design/components";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Field, GetCampaignRes, Prop } from "../openapi-generated/lmpSchemas";
import { fetchAddCampaign, fetchAddFields, fetchEditCampaign, fetchGetFields } from "../openapi-generated/lmpComponents";
import { useNotificationBar } from "../common/hooks/NotificationsBar";
import { CampaignTypes, dispatchModes, encAlgs, hashingAlgs, retentionPeriod, targetSystems } from "../Constants";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useGlobal } from "../common/hooks/GlobalContext";
  
  const EditPage = () => {
    const notificationBar = useNotificationBar();
    const navigate = useNavigate();
    const location = useLocation();
    const [items, setItems] = useState<Field[]>([]);
    let message = "";
    const [isLoading, setIsLoading] = useState(false);

    let campaign: GetCampaignRes = location.state && location.state[0]
    const { globalUser, setGlobalUser } = useGlobal();
    const [fields, setFields] = useState<Prop[]>(campaign?.Fields);
    const [name, setName] = useState(campaign?.Name);
    const [type, setType] = useState<OptionDefinition>({ value: campaign?.Type});
    const [enrichFlag, setEnrichFlag] = useState(campaign?.EnrichFlag ? '1' : '0');
    const [hashingFlag, setHashingFlag] = useState(campaign?.HashFlag ? '1' : '0');
    const [retention, setRetention] = useState<OptionDefinition>({label: retentionPeriod.find(x => x.value == campaign?.Retention)?.key, value: campaign?.Retention});
    const [targetSys, setTargetSys] = useState<OptionDefinition>({ value: campaign?.TargetSys});
    const [encAlg, setEncAlg] = useState<OptionDefinition>({ value: campaign?.EncAlg});
    const [hashingAlg, setHashingAlg] = useState<OptionDefinition>({ value: campaign?.HashAlg});
    const [dispatchMode, setDispatchMode] = useState<OptionDefinition>(campaign?.TargetSys =='None' ? {label: '', value: ""} : campaign?.TargetSys == 'VDL' ? {label: 'Real Time', value: "0"} : {label: dispatchModes.find(x => x.value == campaign?.DispatchMode)?.key,value: campaign?.DispatchMode});
    const onHashChanged = useCallback((index: number) => {
      fields[index].Hash = !fields[index].Hash
      setFields([...fields])
    }, [fields]);

  
    const onRequiredChanged = useCallback((index: number) => {
      fields[index].Required = !fields[index].Required
      setFields([...fields])
    }, [fields]);
  
    const onNameChanged = useCallback((name: string, index: number) => {
      fields[index].Name = name
      setFields([...fields])
  
    }, [fields]);
    
    const onAddHeaderButtonClickHandler = useCallback(() => {
      setFields([...fields, { Name: "", Hash: false, Required: false }])
    }, [fields]);
  
    const onRemoveHeaderButtonClickHandler = useCallback((event: any) => {
      fields.splice(event.detail.itemIndex, 1)
      setFields([...fields]);
    }, [fields]);

    const checkIfItemRemovable = useCallback((event: Prop, target: string) => {
      const buttons = document.getElementsByClassName("awsui_remove-button_n4qlp_roecl_155")
      if(buttons.length > 0){
        var b = buttons[fields.findIndex(x => x.Name == event.Name)] as HTMLButtonElement

        if(b && target == 'Campaign' && ["msisdn", "email", "audience_id"].includes(event.Name)){
          if (fields.filter(x => x.Name == "msisdn" || x.Name == "email" || x.Name == "audience_id").length <= 1) {
            b.classList.add("awsui_disabled_vjswe_1himx_202")
          } else {
            b.classList.remove("awsui_disabled_vjswe_1himx_202")
          }
        }
        else if (b && target == 'VDL') {
          if(event.Name == "msisdn")
            b.classList.add("awsui_disabled_vjswe_1himx_202")
          else b.classList.remove("awsui_disabled_vjswe_1himx_202")
        }
      }
        return true
  
    }, [fields]);

    const checkIfNotValid = (target: string) => {
      if(target == 'Campaign'){
        if (fields.filter(x => (x.Name == "msisdn" || x.Name == "email" || x.Name == "audience_id") && x.Required).length < 1){
          message = "At least one attribute among 'msisdn', 'email' and 'audience_id' is required"
          return true
        } else return false
      }
      else if (target == 'VDL') {
        if (fields.filter(x => x.Name == "msisdn" && x.Required).length < 1){
          message = "The 'msisdn' attribute is required"
          return true
        } else return false
      }
        else return false
  
    };

    useEffect(() => {
        fetchGetFields({
        }).then((response) => setItems(response.Items!))
    }, []);

  
      const editCampaignHandler = useCallback(() => {
        setIsLoading(true);
        notificationBar.clear();
        notificationBar.push({
          content: "Editing Campaign",
          dismissible: false,
          loading: true,
          type: "info",
        });
        fetchEditCampaign({
          queryParams: globalUser?.Role != 'Admin' ? { Area: globalUser?.Area } : {},
          body: {
            CampaignID: campaign.CampaignId,
            Name: name,
            Type: type.value!,
            EnrichFlag: enrichFlag,
            Retention: retention.value!,
            TargetSys: targetSys.value!,
            DispatchMode: dispatchMode.value!,
            HashFlag: hashingFlag,
            HashAlg: hashingAlg.value!,
            EncAlg: encAlg.value!,
            User: globalUser?.Name + " " + globalUser?.Surname,
            Fields: fields
          },
        })
          .finally(() => {
            setIsLoading(false);
            notificationBar.clear();
          })
          .then((campaign) => {
            notificationBar.push({
              content: `Campaign ${campaign.Name} (ID: ${campaign.CampaignId}) updated`,
              dismissible: true,
              type: "success",
            });
            navigate("/campaigns");
          })
          .catch((error) => {
            const message = error?.stack?.message || `Unexpected error: ${error}`;
            notificationBar.push({
              content: message,
              dismissible: true,
              type: "error",
            });
          });
      }, [
        navigate,
        notificationBar,
      ]);

    const definitions: AttributeEditorProps.FieldDefinition<Prop>[] = [
      {
        label: 'Name',
        control: (prop: Prop, index: number) => {
          return (
            <Select
              selectedOption={{ value: prop.Name }}
              options={items?.filter( (item) => item.Area.includes(campaign.Area) ) .filter(x => !fields.map(x => x.Name).includes(x.Name)).map((r) => ({ value: r.Name }))}
              placeholder="Select a property"
              onChange={(event) => onNameChanged(event.detail.selectedOption.value!, index)}
              filteringType="auto"
            />
          );
        },
      },
      {
        label: 'To Hash',
        control: (prop: Prop,  index: number) => <Checkbox disabled={hashingFlag == '0' ? true : false} checked={prop.Hash} onChange={_ => onHashChanged( index)} />
      },
      {
        label: 'Required',
        control: (prop: Prop, index: number) => <Checkbox checked={prop.Required} onChange={_ => onRequiredChanged(index)} />
      },
    ];
    
    const form = (
    <form onSubmit={e => {
            e.preventDefault();
            editCampaignHandler()
        }
    } >
      <Form 
        actions={
            <SpaceBetween direction="horizontal" size="xs">
            <Button href="/campaigns" variant="link">
                Cancel
            </Button>
            <Button disabled={checkIfNotValid(targetSys.value!)} variant="primary">Save</Button>
            </SpaceBetween>
        }
        header={<><div style={{ marginTop: 25 }}></div><Header variant="h1">Editing {campaign?.Name} (ID: {campaign?.CampaignId})</Header></>}>
        <Alert
          statusIconAriaLabel="Info"
          header="Attention!"
        >
          You can change the following configuration but the ID cannot be overwritten
        </Alert>
        <div style={{marginTop: 25}}></div>
        <Container>
            <SpaceBetween direction="vertical" size="l">
            <div>
                <h2>Campaign content</h2>
                <SpaceBetween direction="horizontal" size="l">
                  <div style={{ minWidth: 300 }}>
                    <FormField label="Name" stretch={true}>
                    <Input
                        placeholder="Campaign name"
                        onChange={({ detail }) => setName(detail.value)}
                        ariaLabel="Campaign name" 
                        value={name} 
                        invalid={name == ""}    
                        autoFocus={true}            
                        />
                    </FormField>
                  </div>
                </SpaceBetween>
                <SpaceBetween direction="horizontal" size="l">
                  <div style={{ minWidth: 300, marginTop: 25 }}>
                    <FormField label="Type" stretch={true}>
                      <Select
                        onChange={({ detail }) =>
                        setType(detail.selectedOption)
                        }
                        selectedOption={type}
                        options={CampaignTypes.map((r) => ({ value: r }))}
                        selectedAriaLabel="Type"
                        expandToViewport
                        virtualScroll
                      />
                    </FormField>
                  </div>
                </SpaceBetween>
              </div>
            </SpaceBetween>
        </Container>
        <div style={{marginTop: 25}}></div>
        <Container>
          <table className="FullWidth">
          <tbody>
            <tr>
              <td className="FullWidth">
                <SpaceBetween direction="horizontal" size="xxl">
                  <div>
                    <h2>Data Enhancement & Dispatching</h2>
                    <SpaceBetween direction="horizontal" size="l">
                      <div style={{ minWidth: 300 }}>
                        <FormField label="Enhancement" stretch={true}>
                          <RadioGroup
                            items={[
                              {
                                value: '1',
                                label: 'Enabled',
                              },
                              {
                                value: '0',
                                label: 'Disabled',
                              },
                            ]}
                            value={enrichFlag}
                            onChange={({ detail }) => setEnrichFlag(detail.value)} />
                        </FormField>
                      </div>
                    </SpaceBetween>
                    <SpaceBetween direction="horizontal" size="l">
                      <div style={{ minWidth: 300, marginTop: 25 }}>
                        <FormField label="Retention period" stretch={true}>
                          <Select
                            disabled
                            selectedOption={retention}
                            onChange={({ detail }) => setRetention(detail.selectedOption)}
                            options={retentionPeriod.map((r) => ({ label: r.key, value: r.value }))}
                            selectedAriaLabel="Retention period"
                            expandToViewport
                            virtualScroll />
                        </FormField>
                      </div>
                    </SpaceBetween>
                    <SpaceBetween direction="horizontal" size="l">
                      <div style={{ minWidth: 300, marginTop: 25 }}>
                        <FormField label="Target system" stretch={true}>
                          <Select
                            selectedOption={targetSys}
                            onChange={({ detail }) => 
                            {
                              setTargetSys(detail.selectedOption);
                              if (detail.selectedOption.value == 'Campaign') {
                                setDispatchMode({label:dispatchModes[0].key, value: dispatchModes[0].value})
                                document.getElementById("dispatch_div")!.style.display = "block"
                              }
                              else if (detail.selectedOption.value == 'VDL') {
                                setDispatchMode({label: 'Real Time', value: "0"})
                                document.getElementById("dispatch_div")!.style.display = "block"
                              }
                              else {
                                document.getElementById("dispatch_div")!.style.display = "none"
                                setDispatchMode({label: '', value: ""})
                              }
                            }
                          }
                            options={campaign.Area != 'VBI' ? targetSystems.map((r) => ({ value: r })) : targetSystems.filter(x => x != "Campaign").map((r) => ({ value: r }))}
                            selectedAriaLabel="Target System"
                            expandToViewport
                            virtualScroll />
                        </FormField>
                      </div>
                      <div style={{ minWidth: 300, marginTop: 25, display: targetSys.value == "None" ? "none": "block"}} id="dispatch_div">
                        <FormField label="Sending mode" stretch={true}>
                          <Select
                            disabled={targetSys.value == 'VDL'}
                            selectedOption={dispatchMode}
                            onChange={({ detail }) => setDispatchMode(detail.selectedOption)}
                            options={campaign?.TargetSys =='None' ? [{label: '', value: ""}] : targetSys.value == 'VDL' ? [{label: 'Real Time', value: "0"}] : dispatchModes.map((r) => ({ label: r.key, value: r.value }))}
                            selectedAriaLabel="Dispatch Modes"
                            expandToViewport
                            virtualScroll />
                        </FormField>
                      </div>
                    </SpaceBetween>
                  </div>
                </SpaceBetween>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
      <div style={{marginTop: 25}}></div>
      <Container>
        <table className="FullWidth">
          <tbody>
            <tr>
              <td className="FullWidth">
                <SpaceBetween direction="horizontal" size="xxl">
                  <div>
                    <h2>Security and Encryption</h2>
                    <SpaceBetween direction="horizontal" size="l">
                      <div style={{ minWidth: 300 }}>
                        <FormField label="Hashing configurations" stretch={true}>
                          <RadioGroup
                            items={[
                              {
                                value: '1',
                                label: 'Enabled',
                              },
                              {
                                value: '0',
                                label: 'Disabled',
                              },
                            ]}
                            value={hashingFlag}
                            onChange={({ detail }) => setHashingFlag(detail.value)} />
                        </FormField>
                      </div>
                      <div style={{ minWidth: 300, display: hashingFlag == "0" ? "none" : "block"}}>
                        <FormField label="Hashing Algorithm" stretch={true}>
                          <Select
                            disabled
                            selectedOption={hashingAlg}
                            onChange={({ detail }) => setHashingAlg(detail.selectedOption)}
                            options={hashingAlgs.map((r) => ({ value: r }))}
                            selectedAriaLabel="Hashing Algorithm"
                            expandToViewport
                            virtualScroll />
                        </FormField>
                      </div>
                    </SpaceBetween>
                    <SpaceBetween direction="horizontal" size="l">
                      <div style={{ minWidth: 300, marginTop: 25 }}>
                        <FormField label="Encryption Algorithm" stretch={true}>
                          <Select
                            disabled
                            selectedOption={encAlg}
                            onChange={({ detail }) => setEncAlg(detail.selectedOption)}
                            options={encAlgs.map((r) => ({ value: r }))}
                            selectedAriaLabel="Ecryption Algorithm"
                            expandToViewport
                            virtualScroll />
                        </FormField>
                      </div>
                    </SpaceBetween>
                  </div>
                </SpaceBetween>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
      <div style={{marginTop: 25}}></div>
      <Container>
      { checkIfNotValid(targetSys.value!) && <><Alert
      statusIconAriaLabel="Info"
      header="Required field"
    >
      {message}
    </Alert><br></br></>}
      <Form variant="embedded">
      <SpaceBetween direction="vertical" size="l">
        <div style={{ minWidth: 200 }}>
          <h2>Fields</h2>
          <AttributeEditor
            removeButtonText="Remove"
            addButtonText="Add new field"
            empty="No fields found."
            definition={definitions}
            onAddButtonClick={_ => onAddHeaderButtonClickHandler()}
            onRemoveButtonClick={onRemoveHeaderButtonClickHandler}
            items={fields}
            isItemRemovable={i => checkIfItemRemovable(i, targetSys.value!)}
          />
        </div>
      </SpaceBetween>
    </Form>
      </Container>
      </Form>
      </form>
    );
  
    return (
        <>{campaign == null ? <Navigate to={"/campaigns"} replace /> : form}</>
    )
  };
  
  export default EditPage;
  