import {
    Container,
    Form,
    SpaceBetween,
    Header,
    ColumnLayout,
    BarChart,
    Box,
    Button,
    PieChart,
    Spinner,
  } from "@cloudscape-design/components";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Field, GetCampaignRes, LeadsByMonth, Prop, User } from "../openapi-generated/lmpSchemas";
import { CampaignTypes, dispatchModes, encAlgs, hashingAlgs, months, retentionPeriod, targetSystems } from "../Constants";
import { useEffect, useState } from "react";
import { fetchGetCampaign, fetchGetLeadsByMonth } from "../openapi-generated/lmpComponents";
import { Auth } from "aws-amplify";
import { useGlobal } from "../common/hooks/GlobalContext";

  const DashboardPage = () => {
    const [campaigns, setCampaigns] = useState<GetCampaignRes[]>([]);
    const [campaigns_last_30_days, setCampaigns_last_30_days] = useState<GetCampaignRes[]>([]);
    const [leadsByMonth, setLeadsByMonth] = useState<LeadsByMonth[]>([]);
    const [lostLeads, setLostLeads] = useState(0);
    const [spinner1, setSpinner1] = useState(true);
    const [spinner2, setSpinner2] = useState(true);
    const [spinner3, setSpinner3] = useState(true);
    const { globalUser, setGlobalUser } = useGlobal();
    console.log(globalUser)

    useEffect(() => {
      setSpinner1(true)
      if(globalUser?.Area)
        fetchGetCampaign({ queryParams: globalUser?.Role != 'Admin' ? { Area: globalUser?.Area, State: "Active"} : {State: "Active"}}
        ).then((response) => {
          setCampaigns(response.Items);
          setLostLeads(response.LostLeads!);
          setSpinner1(false)
        })
  }, []);

  useEffect(() => {
    setSpinner2(true)
    if(globalUser?.Area)
      fetchGetCampaign({ queryParams: globalUser?.Role != 'Admin' ? {Days: 30, State: "Active", Area: globalUser?.Area} : {Days: 30, State: "Active"} }
      ).then((response) => {
        setCampaigns_last_30_days(response.Items)
        setSpinner2(false)
      })
}, []);

  useEffect(() => {
    setSpinner3(true)
    if(globalUser?.Area)
      fetchGetLeadsByMonth({ queryParams: globalUser?.Role != 'Admin' ? { Area: globalUser?.Area } : {}}
      ).then((response) => {
        setLeadsByMonth(response)
        setSpinner3(false)
      })
}, []);
  
    const form = (
      <><Form header={<><div style={{ marginTop: 20 }}></div><Header variant="h1">General Dashboard</Header></>}>     
      <Container>
        <Header headingTagOverride="h3">Overview</Header> 
        <SpaceBetween direction="vertical" size="l">
            {/* <h2>Overview</h2> */}
            <ColumnLayout columns={4} variant="default">
              <div>
                <h3>Active campaigns</h3>
                <h1 style={{color: "#e40501"}}>{campaigns.reduce((count, obj) => 
                    {
                      if (obj.State === 'Active') {
                          return count + 1;
                      }
                      return count;
                    }, 0)}
                </h1>
              </div>
              {/* <div>
                <h3>Target Systems</h3>
                <h1 style={{color: "#e40501"}}>{targetSystems.length}
                </h1>
              </div> */}
              <div>
                <h3>Leads collected</h3>
                <h1 style={{color: "#e40501"}}>{campaigns.reduce((sum, obj) => 
                      {
                        return sum + parseInt(obj.LeadsColl);
                      }, 0)}
                </h1>
              </div>
              <div>
                <h3>Leads dispatched</h3>
                <h1 style={{color: "#e40501"}}>{campaigns.reduce((sum, obj) => 
                      {
                        return sum + parseInt(obj.LeadsDisp);
                      }, 0)}</h1>
                
              </div>
              <div>
                <h3>Leads lost *</h3>
                {/* <h1 style={{color: "#e40501"}}>{campaigns.reduce((sum, obj) => 
                      {
                        return sum + parseInt(obj.LeadsLost);
                      }, 0)}</h1> */}
                <h1 style={{color: "#e40501"}}>{lostLeads}</h1>
              </div>
            </ColumnLayout>
            <p style={{marginBottom:0}}>* This is a global counter, relative to all campaign, not only the active ones</p>
        </SpaceBetween>
      </Container>
        <SpaceBetween direction="vertical" size="l" >
            {/* <h2>Overview</h2> */}
            <ColumnLayout columns={2} >
              <div style={{ marginTop: 25 }}>
                <Container>
                <Header headingTagOverride="h3">Leads by Campaign</Header>
                <BarChart
                  series={campaigns_last_30_days.length > 0 ? [
                    {
                      title: "Leads collected",
                      type: "bar",
                      data: campaigns_last_30_days.map((c) => {
                        return {
                          x: c.Name, y: parseInt(c.LeadsColl)
                        }})
                    },
                    {
                      title: "Leads dispatched",
                      type: "bar",
                      data: campaigns_last_30_days.map((c) => {
                        return {
                          x: c.Name, y: parseInt(c.LeadsDisp)
                        }})
                    }
                  ] : []}
                  xScaleType="categorical"
                  xDomain={campaigns_last_30_days.map(x => x.Name)}
                  //yDomain={[0, findMaxLeadsValue()]}
                  ariaLabel="Stacked bar chart"
                  height={300}
                  hideFilter
                  //stackedBars
                  xTitle="Campaign"
                  yTitle="Total Leads"
                  className="graphLMP"
                  empty={
                    <Box textAlign="center" color="inherit">
                      <b>No data available</b>
                      <Box variant="p" color="inherit">
                        There is no data relative to active campaigns in the last 30 days
                      </Box>
                    </Box>
                  }
                />
                </Container>
              </div>
              <div style={{ marginTop: 25 }}>
              <Container>
                  <Header headingTagOverride="h3">Leads by Month</Header>
                  <BarChart
                    series={leadsByMonth.length > 0 ? [
                      {
                        title: "Leads collected",
                        type: "bar",
                        data: leadsByMonth.map((c) => {
                          return {
                            x: months[parseInt(c.Month.split("-")[0])-1] + " " + c.Month.split("-")[1], y: parseInt(c.LeadsColl)
                          }})
                      },
                      {
                        title: "Leads dispatched",
                        type: "bar",
                        data: leadsByMonth.map((c) => {
                          return {
                            x: months[parseInt(c.Month.split("-")[0])-1] + " " + c.Month.split("-")[1], y: parseInt(c.LeadsDisp)
                          }})
                      }
                    ] : []}
                    xScaleType="categorical"
                    xDomain={leadsByMonth.map(x => x.Month).map(ma => months[parseInt(ma.split("-")[0])-1] + " " + ma.split("-")[1])}
                    //yDomain={[0, findMaxLeadsValue()]}
                    ariaLabel="Stacked bar chart"
                    height={300}
                    hideFilter
                    //stackedBars
                    xTitle="Month"
                    yTitle="Total Leads"
                    className="barLMP"
                    empty={
                      <Box textAlign="center" color="inherit">
                        <b>No data available</b>
                        <Box variant="p" color="inherit">
                        There is no data relative to active campaigns in the last 10 month
                        </Box>
                      </Box>
                    }
                  />
              </Container>
              </div>
            </ColumnLayout>
            <ColumnLayout columns={1}>
            {globalUser?.Role == 'Admin' ? 
            (<Container>
                <Header headingTagOverride="h3">Leads by Area</Header>
                <PieChart
                  data={[
                      { title: "VBI", value: campaigns.reduce((sum, obj) => 
                      {
                        if(obj.Area == "VBI")
                          return sum + parseInt(obj.LeadsColl);
                        return sum
                      }, 0), percentage: campaigns.reduce((sum, obj) => 
                      {
                        if(obj.Area == "VBI")
                          return sum + parseInt(obj.LeadsDisp);
                        return sum
                      }, 0)},
                      { title: "CBU", value: campaigns.reduce((sum, obj) => 
                      {
                        if(obj.Area == "CBU")
                          return sum + parseInt(obj.LeadsColl);
                        return sum
                      }, 0), percentage: campaigns.reduce((sum, obj) => 
                      {
                        if(obj.Area == "CBU")
                          return sum + parseInt(obj.LeadsDisp);
                        return sum
                      }, 0)},
                    ]}
                    detailPopoverContent={(datum, sum) => [
                      {
                        key: 'Collected',
                        value: datum.value,
                      },
                      {
                        key: 'Dispatched',
                        value: datum.percentage,
                      },
                    ]}
                  // visibleSegments={[
                  //   { title: "Item A", percentage: 40, value: 40 },
                  //   { title: "Item B", percentage: 25, value: 25 },
                  //   { title: "Item C", percentage: 20, value: 20 },
                  //   { title: "Item D", percentage: 10, value: 10 },
                  //   { title: "Item E", percentage: 5, value: 5 }
                  // ]}
                  segmentDescription={(datum, sum) =>
                    `${datum.value} leads, ${(
                      (datum.value / sum) *
                      100
                    ).toFixed(0)}%`
                  }
                  ariaDescription="Donut chart showing generic example data."
                  ariaLabel="Donut chart"
                  hideFilter
                  hideLegend
                  innerMetricDescription="collected leads"
                  innerMetricValue={campaigns.reduce((sum, obj) => 
                    {
                      return sum + parseInt(obj.LeadsColl);
                    }, 0).toString()}
                  size="large"
                  variant="donut"
                  empty={
                    <Box textAlign="center" color="inherit">
                      <b>No data available</b>
                      <Box variant="p" color="inherit">
                        There is no data available
                      </Box>
                    </Box>
                  }
                />
              </Container>) : ""}
            </ColumnLayout>
        </SpaceBetween>
        </Form>
      </>
              
    );
  
    return (
        <>{globalUser?.Role == 'NONE' ? <Navigate to={"/campaign"} replace /> : spinner1 && spinner2 && spinner3 ? <Spinner/> : form}</>
    )
  };
  
  export default DashboardPage;
  