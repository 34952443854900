import replyLogo from "../../../assets/reply-logo.svg";

const Footer = () => {
  const logoSize = 40;
  return (
    <div className="Footer">
        {/* <img
          className=""
          style={{
            width: logoSize,
            margin: "12px",
            marginRight: "20px"
          }}
          src={replyLogo}
          alt="reply-logo"
        ></img> */}
        {/* <div style={{fontSize: 10, marginBottom: 5, marginRight: 15}}>&copy; Reply 2023</div> */}
      </div>
  );
};

export default Footer;
