import { Spinner } from "@cloudscape-design/components";

const Splash = () => {
  return (
    <div
      className="vertical-center"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "var(--color-background-button-primary-active-vg0knj)"
      }}
    >
      <Spinner size="large"/>
    </div>
  );
};

export default Splash;
