import { TopNavigation } from "@cloudscape-design/components";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import logo from "../../../assets/customer-logo.png";
import { Auth } from "aws-amplify";
import Cookies from "universal-cookie";
import ThemeToggle from "./ThemeToggle";
import Config from "../../../AppConfig";
import { User } from "../../../openapi-generated/lmpSchemas";
import { text } from "stream/consumers";
import { fetchLogout } from "../../../openapi-generated/lmpComponents";

export const profileActions = [
  { type: "button", id: "signout", text: "Sign out" },
];

const Head: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const domNode = document.querySelector("#header") as Element;
  return createPortal(children, domNode);
};

const i18nStrings = {
  searchIconAriaLabel: "Search",
  searchDismissIconAriaLabel: "Close search",
  overflowMenuTriggerText: "More",
  overflowMenuTitleText: "All",
  overflowMenuBackIconAriaLabel: "Back",
  overflowMenuDismissIconAriaLabel: "Close menu",
};

const MainHeader = () => {
  const [cookies] = useState(new Cookies());
  const [email, setEmail] = useState("");
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const getEmail = async () => {
      const data = (await Auth.currentSession()).getIdToken().decodePayload();
      setEmail(data.email);
      const user_info = JSON.parse(data.user_info);
      setUser({
        Name: user_info.Name,
        Surname: user_info.Surname,
        Role: user_info.Role,
        Area: user_info.Area,
        LastLogin: user_info.LastLogin
      })
    };
    getEmail();
  }, []);

  const topNavigationParentRef =
    useRef() as React.MutableRefObject<HTMLDivElement>;
  //const [themeToggleVisibility, setThemeToggleVisibility] = useState(false);

  useEffect(() => {
    const utilities =
      topNavigationParentRef.current.children[1]?.firstChild?.firstChild
        ?.firstChild?.childNodes[1];
    if (utilities) {
      const clone = utilities.firstChild!.cloneNode();
      clone.appendChild(topNavigationParentRef.current.children[0]);
      utilities.insertBefore(clone, utilities.firstChild);
      //setThemeToggleVisibility(false);
    }
  }, [topNavigationParentRef]);



  function getCurrentFormattedTimestamp(): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC' // Adjust time zone as needed
    };
  
    const formatter = new Intl.DateTimeFormat('it-IT', options);
    const formattedTimestamp = formatter.format(new Date());
    
    return formattedTimestamp;
  }
  
  return (
    <Head>
      <div id="top-navigation-parent" ref={topNavigationParentRef}>
        <div
          style={{
            display: "flex",
            //visibility: themeToggleVisibility ? "visible" : "collapse",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <ThemeToggle /> */}
        </div>
        <TopNavigation
          i18nStrings={i18nStrings}
          identity={{
            href: "/",
            title: Config.appName,
            logo: { src: logo },
          }}
          utilities={[
            {
              type: "button",
              text: "Last Access " + (user?.LastLogin ? user?.LastLogin.split(".")[0] : getCurrentFormattedTimestamp())
            },
            {
              type: "menu-dropdown",
              text: user?.Name + " " + user?.Surname!,
              description: email + " | " + (user?.Role),
              iconName: "user-profile",
              onItemClick: ({ detail }) => {
                if (detail.id === "signout") {
                  fetchLogout({body: {Username: email}}).then(() => {
                    cookies.remove("token", { path: "/" });
                    cookies.remove("base_path", { path: "/" });
                    Auth.signOut();
                  });
                }
              },
              items: profileActions,
            },
          ]}
        />
      </div>
    </Head>
  );
};

export default MainHeader;