import {
  Button,
  Container,
  FormField,
  Select,
  SpaceBetween,
  Spinner,
  Input,
  RadioGroup,
  Tiles
} from "@cloudscape-design/components";
import { useCallback, useEffect, useState } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

import { WizardStepComponent } from "./WizardStep";
import { CampaignTypes } from "../../Constants";
import { Flex } from "@aws-amplify/ui-react";
import { User } from "../../openapi-generated/lmpSchemas";

const BasicsStep: WizardStepComponent = (props: {user: User}) => {
  const [name, setName] = useState("");
  const [type, setType] = useState<OptionDefinition>({ value: CampaignTypes[0]});
  const [area, setArea] = useState("CBU");
  const [isLoading, setIsLoading] = useState(false);

  const form = 
    <table className="FullWidth">
      <tbody>
        <tr>
          <td className="FullWidth">
            <SpaceBetween direction="horizontal" size="xxl">
              <div>
                <h2>Campaign content</h2>
                <p>Choose name and type of the campaign:</p>
                <SpaceBetween direction="horizontal" size="l">
                  <div style={{ minWidth: 300 }}>
                    <FormField label="Name" stretch={true} errorText={name == "" && "Please enter a name"}>
                    <Input
                        placeholder="Campaign name"
                        onChange={({ detail }) => setName(detail.value)}
                        ariaLabel="Campaign name" 
                        value={name} 
                        invalid={name == ""}    
                        autoFocus={true}            
                        />
                    </FormField>
                  </div>
                </SpaceBetween>
                <SpaceBetween direction="horizontal" size="l">
                  <div style={{ minWidth: 300, marginTop: 25 }}>
                    <FormField label="Type" stretch={true}>
                      <Select
                        onChange={({ detail }) =>
                          setType(detail.selectedOption)
                        }
                        selectedOption={type}
                        options={CampaignTypes.map((r) => ({ value: r }))}
                        selectedAriaLabel="Type"
                        expandToViewport
                        virtualScroll
                      />
                    </FormField>
                  </div>
                </SpaceBetween>
              </div>
            </SpaceBetween>
          </td>
         
        </tr>
      </tbody>
    </table>;

const form2 = 
    <table className="FullWidth">
      <tbody>
        <tr>
          <td className="FullWidth">
            <SpaceBetween direction="horizontal" size="xxl">
              <div>
                <h2>Area of interest</h2>
                <SpaceBetween direction="horizontal" size="l">
                  <div style={{ minWidth: 300 }}>
                    <FormField label="Type" stretch={true} >
                    <RadioGroup
                          items={[
                            {
                              value: 'CBU',
                              label: 'CBU',
                              description: "Consumer"
                            },
                            {
                              value: 'VBI',
                              label: 'VBI',
                              description: "Business"
                            },
                          ]}
                          value={area}
                          onChange={({ detail }) => setArea(detail.value)} />
                    </FormField>
                  </div>
                </SpaceBetween>
              </div>
            </SpaceBetween>
          </td>
         
        </tr>
      </tbody>
    </table>;

  const spinner = (
    <div
      className="vertical-center"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "var(--color-background-button-primary-active-vg0knj)",
      }}
    >
      <Spinner size="large" />
    </div>
  );

  return {
    data: {
      name: name.valueOf(),
      type: type.value,
      area: area.valueOf()
    },
    title: "Basics",
    description:
      "Step by step configuration of the campaign",
    isOptional: false,
    isLoading: name === "",
    content: (
      <><Container>
        <>
          {form}
        </>
      </Container>
      <div style={{ margin: 50 }}></div>
      {props.user.Role == 'Admin' ? (<Container>
          <>
            {form2}
          </>
        </Container>) : ""}</>
    ),
  };
};

export default BasicsStep;
