import { Table, Box, Icon, Link, TextFilter, Button } from "@cloudscape-design/components";
import TableHeader from "../common/components/elements/TableHeader";
import usePaginationHandler from "../common/hooks/PaginationHandler";
import {
  useTablePreferences,
  ColumnDefinition,
} from "../common/components/elements/TablePreferences";
import { Role } from "../openapi-generated/lmpSchemas";
import {
  fetchGetRoles,
} from "../openapi-generated/lmpComponents";
import CreateEditAccountModal from "../components/modals/CreateEditAccount";
import { useEffect, useState } from "react";
import ConfirmModal from "../common/components/elements/ConfirmModal";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState, TableNoMatchState } from "../common/components/elements/Empty";
import { useGlobal } from "../common/hooks/GlobalContext";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const getTextFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

const UsersPage = () => {
  const { globalUser, setGlobalUser } = useGlobal();
  const navigate = useNavigate();
  if(globalUser?.Role != 'Admin') navigate("/campaigns");
  const [email, setEmail] = useState("");

  const columnDefinitions: ColumnDefinition<any>[] = [
    {
      id: "user_id",
      header: "UserID",
      visible: true,
      cell: (account) => (
        <Button variant="link" onClick={ () => {
          setSelectedItems([account])
          setEditModalVisible(true)
          return undefined
        }}
        >{account.UserID}</Button>
      ),
    },
    {
      id: "name",
      header: "Name",
      visible: true,
      cell: (account) => account.Name || "",
    },
    {
      id: "surname",
      header: "Surname",
      visible: true,
      cell: (account) => account.Surname || "",
    },
    {
      id: "role",
      header: "Role",
      visible: true,
      cell: (account) => account.Role == 'NONE' ? "-" : (account.Role || "-"),
    },
    {
      id: "area",
      header: "Area",
      visible: true,
      cell: (account) => account.Role == 'Admin' ? "-" : (account.Area || "-"),
    },
  ];

  useEffect(() => {
    const getEmail = async () => {
      const data = (await Auth.currentSession()).getIdToken().decodePayload();
      setEmail(data.email)
    };
    getEmail();
  }, []);
  const [selectedItems, setSelectedItems] = useState<Role[]>([]);
  const { collectionPreferences, preferences } = useTablePreferences({
    pageSize: 20,
    contentDensity: "comfortable",
    columnDefinitions,
  });
  const { pagination, currentPage, isLoading, resetPagination } =
    usePaginationHandler({
      api: fetchGetRoles,
      pageSize: preferences.pageSize || 20,
      preInitialize: true,
    });

    // const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    //   currentPage ||  [
    //     {
    //       "UserID": "mario.rossi@vodafone.com",
    //       "Name": "Mario",
    //       "Surname": "Rossi",
    //       "Role": "Admin",
    //       "Area": "-"
    //     },
    //     {
    //       "UserID": "paolo.verdi@vodafone.com",
    //       "Name": "Paolo",
    //       "Surname": "Verdi",
    //       "Role": "Power User",
    //       "Area": "VBI"
    //     }
    //   ],
    //   {
    //     filtering: {
    //       empty: <TableEmptyState resourceName="Campaign" />,
    //       noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
    //     },
    //     pagination: { pageSize: preferences.pageSize },
    //     selection: {},
    //   }
    // );

  const [editModalVisible, setEditModalVisible] = useState(false);
  const editModal = selectedItems.length && (
    <CreateEditAccountModal
      edit={true}
      userID={selectedItems[0].UserID}
      name={selectedItems[0].Name}
      surname={selectedItems[0].Surname}
      role={selectedItems[0].Role}
      area={selectedItems[0].Area}
      onClose={() => setEditModalVisible(false)}
      onConfirm={() => {
        setEditModalVisible(false);
        setSelectedItems([]);
        resetPagination();
      }}
    />
  );

  //Removing selection on refresh
  useEffect(() => {
    setSelectedItems([]);
  }, []);

  return (
    <>
      {editModalVisible && editModal}
      <Table
        variant="full-page"
        trackBy="UserID"
        stickyHeader
        header={
          <TableHeader
          itemsCount={
            currentPage && currentPage.filter(x => x.UserID != email).length
          }
            // actions={[
            //   {
            //     label: "Edit permissions",
            //     iconName: "edit",
            //     variant: "normal",
            //     onClick: () => setEditModalVisible(true),
            //     disabled: selectedItems.length === 0,
            //   },
            // ]}
          >
            Users
          </TableHeader>
        }
        loading={isLoading}
        contentDensity={preferences.contentDensity}
        visibleColumns={preferences.visibleContent}
        columnDefinitions={columnDefinitions}
        //selectionType="single"
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        items={currentPage && currentPage.filter(x => x.UserID != email)}
        loadingText="Loading users"
        empty={
          <Box variant="p" color="text-status-info" textAlign="center">
            No users to display.
          </Box>
        }
        // filter={
        //   <TextFilter
        //     {...filterProps}
        //     filteringAriaLabel="Filter users"
        //     filteringPlaceholder="Find users"
        //     filteringClearAriaLabel="Clear"
        //     countText={getTextFilterCounterText(filteredItemsCount!)}
        //   />
        // }
        pagination={pagination}
        preferences={collectionPreferences}
        // footer={
        //   selectedItems.length > 0 && (
        //     <Box textAlign="center">
        //       <Link onFollow={() => setSelectedItems([])}>
        //         <Icon name="close" /> deselect
        //       </Link>
        //     </Box>
        //   )
        // }
      />
    </>
  );
};

export default UsersPage;
