import {
    Container,
    Form,
    SpaceBetween,
    Header,
    ColumnLayout,
    Button,
  } from "@cloudscape-design/components";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Field, GetCampaignRes, Prop } from "../openapi-generated/lmpSchemas";
import { CampaignTypes, dispatchModes, encAlgs, hashingAlgs, retentionPeriod, targetSystems } from "../Constants";
import React from "react";
import { useGlobal } from "../common/hooks/GlobalContext";
  
  const DetailsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { globalUser, setGlobalUser } = useGlobal();
    let campaign: GetCampaignRes = location.state && location.state[0]

    const form = (
      <><Form header={<><div style={{ marginTop: 20 }}></div>
      <Header variant="h1" actions={globalUser?.Role != 'Backoffice' && <Button iconName="edit" onClick={ () => {
        navigate("/edit", {state: [location.state[0]]}); return undefined}} >Edit</Button>}>{campaign?.Name} (ID: {campaign?.CampaignId}) - Details</Header></>}>     
      <Container>
        <Header headingTagOverride="h3">Overview</Header> 
        <SpaceBetween direction="vertical" size="l">
            {/* <h2>Overview</h2> */}
            <ColumnLayout columns={4} variant="text-grid">
              <div>
                <h3>Campaign type</h3>
                <h1 style={{color: "#e40501"}}>{campaign?.Type}</h1>
                <p><i>Last Modified by <b>{campaign?.User}</b></i></p>
              </div>
              <div>
                <h3>Leads collected</h3>
                <h1 style={{color: "#e40501"}}>{campaign?.LeadsColl}</h1>
              </div>
              <div>
                <h3>Leads dispatched</h3>
                <h1 style={{color: "#e40501"}}>{campaign?.LeadsDisp}</h1>
              </div>
              <div>
                <h3>Leads lost</h3>
                <h1 style={{color: "#e40501"}}>{campaign?.LeadsLost}</h1>
              </div>
            </ColumnLayout>
        </SpaceBetween>
      </Container>
        <SpaceBetween direction="vertical" size="l">
            {/* <h2>Overview</h2> */}
            <ColumnLayout columns={2} >
              <div style={{ marginTop: 25 }}>
                <Container>
                <Header headingTagOverride="h3">Data Enhancement & Dispatching</Header>
                <h4 style={{marginBottom: 0}}>Enrichment</h4>
                <p  style={{marginTop: 0}}>{campaign?.EnrichFlag == "0" ? "Disabled" : "Enabled"}</p>
                <h4 style={{marginBottom: 0}}>Retention Period</h4>
                <p style={{marginTop: 0}}>{retentionPeriod.find(x => x.value == campaign?.Retention)?.key}</p>
                <div className="columns">
                  <div className="column">
                    <h4 style={{marginBottom: 0, marginTop: 0}}>Target System</h4>
                    <p  style={{marginTop: 0}}>{campaign?.TargetSys}</p>
                  </div>
                  <div className="column" style={{visibility: campaign?.TargetSys == "None" ? "hidden": "visible"}}>
                    <h4 style={{marginBottom: 0, marginTop: 0}}>Sending Mode</h4>
                    <p  style={{marginTop: 0}}>{campaign?.TargetSys =='None' ? '' : campaign?.TargetSys =='VDL' ? 'Real time' : dispatchModes.find(x => x.value == campaign?.DispatchMode)?.key}</p>
                  </div>
                </div>
                <div style={{marginTop: 25}}>
                  <Header headingTagOverride="h3">Security & Encryption</Header>
                    <div className="columns">
                      <div className="column">
                        <h4 style={{marginBottom: 0}}>Hashing</h4>
                        <p  style={{marginTop: 0}}>{campaign?.HashFlag == "0" ? "Disabled" : "Enabled"}</p>
                      </div>
                      <div className="column" style={{visibility: campaign?.HashFlag == "0" ? "hidden": "visible"}}>
                        <h4 style={{marginBottom: 0}}>Hashing Algorithm</h4>
                        <p  style={{marginTop: 0}}>{campaign?.HashAlg}</p>
                      </div>
                    </div>
                    <h4 style={{marginBottom: 0}}>Encryption Algorithm</h4>
                    <p style={{marginTop: 0}}>{campaign?.EncAlg}</p>
                </div>
                </Container>
              </div>
              <div style={{ marginTop: 25 }}>
              <Container>
                <Header headingTagOverride="h3">Form Fields</Header>
                <ColumnLayout columns={3} variant="text-grid">
                  {campaign?.Fields.map((element, index) => (
                    <React.Fragment key={index}>
                    <div className="columns">
                        <div className="column">
                          <h4 style={{ marginBottom: 0 }}>{element.Name}</h4>
                          <p style={{ marginTop: 0 }}>{element.Required ? "(Required)" : "(Optional)"}</p>
                        </div>
                        <div className="column" >
                          <h4 style={{ marginBottom: 0 }}>To hash</h4>
                          <p style={{ marginTop: 0 }}>{element.Hash ? "Yes": "No"}</p>
                        </div>
                      </div></React.Fragment>
                  ))}
                
                </ColumnLayout>
              </Container>
              </div>
              
            </ColumnLayout>
        </SpaceBetween></Form>
      </>
              
    );
  
    return (
        <>{campaign == null ? <Navigate to={"/campaigns"} replace /> : form}</>
    )
  };
  
  export default DetailsPage;
  