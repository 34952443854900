import { FormField, Select } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { ValidatedField, ValidatedFieldProps } from "./ValidatedField";

export interface ValidatedSelectProps extends ValidatedFieldProps<string> {
  options: string[];
  selectedAriaLabel?: string;
}

export const ValidatedSelect = (props: ValidatedSelectProps) => {
  const [initialized, setInitialized] = useState(!!props.initialValue);
  const [value, setValue] = useState<any>(props.initialValue);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(props.errorText);

  useEffect(() => {
    if (!value && props.optional) setIsValid(true);
    else if (props.validator) setIsValid(props.validator(value, setError));
    else setIsValid(!!value || !!props.optional);
  }, [value, props.validator]);

  const component = (
    <FormField
      label={
        <div>
          {props.label}
          {props.optional && (
            <>
              <> </>- <i style={{ fontWeight: "normal" }}>optional</i>
            </>
          )}
        </div>
      }
      key={props.key}
      errorText={!isValid && error}
    >
      <Select
        selectedOption={{ value }}
        options={props.options.map((v) => ({ value: v }))}
        placeholder={props.placeholder}
        selectedAriaLabel={props.selectedAriaLabel}
        invalid={initialized && !isValid}
        onChange={({ detail }) => {
          setInitialized(true);
          setValue(detail.selectedOption.value);
        }}
      />
    </FormField>
  );

  return {
    isValid,
    value,
    component,
  } as ValidatedField<string>;
};
