import {
    Container,
    Form,
    SpaceBetween,
    Input,
    AttributeEditor,
    AttributeEditorProps,
    Multiselect,
    Select,
    Header,
    Button,
    Spinner,
  } from "@cloudscape-design/components";
import { Navigate, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Field } from "../openapi-generated/lmpSchemas";
import { fetchAddFields, fetchGetFields } from "../openapi-generated/lmpComponents";
import { useNotificationBar } from "../common/hooks/NotificationsBar";
import { useGlobal } from "../common/hooks/GlobalContext";
import { fieldTypes } from "../Constants";
  
  const FieldsPage = () => {
    const navigate = useNavigate();
    const { globalUser, setGlobalUser } = useGlobal();

    const notificationBar = useNotificationBar();
    const [items, setItems] = useState<Field[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [spinner, setSpinner] = useState(true);

    const setName = useCallback((name: string, index: number) => {
      items[index].Name = name
      setItems([...items])
    }, [items]);

    const setType = useCallback((type: string, index: number) => {
      items[index].Type = type
      setItems([...items])
    }, [items]);

    const setArea = useCallback( (area: string[], index: number) => {
        items[index].Area = area
        setItems([...items])
    }, [items]);
  
    const validateForm = () => {
        let valid = true
        for (let index = 0; index < items.length; index++) {
            if (items[index].Area.length == 0 || items[index].Name == ""){
                valid = false;
                break;
            }
        }
        if (valid){
            AddFieldsHandler()
        }else{
            const message = `One or more fields are misconfigured`;
            notificationBar.push({
            content: message,
            dismissible: true,
            type: "error",
            });
                
        }
    }

    useEffect(() => {
        setSpinner(true)
        fetchGetFields({
        }).then((response) => {
          setItems(response.Items!);
          setSpinner(false)
        })
    }, []);
    
    const onAddHeaderButtonClickHandler = useCallback(() => {
        setItems([...items, { Name: "", Type: "", Area: [] }])
      }, [items]);
    
      const onRemoveHeaderButtonClickHandler = useCallback((event: any) => {
        items.splice(event.detail.itemIndex, 1)
        setItems([...items]);
      }, [items]);
  
    const AddFieldsHandler = useCallback(() => {
        setIsLoading(true);
        notificationBar.clear();
        notificationBar.push({
        content: "Editing Fields ...",
        dismissible: false,
        loading: true,
        type: "info",
        });
        fetchAddFields({
        body: {
            Items: items
        },
        })
        .finally(() => {
            setIsLoading(false);
            notificationBar.clear();
        })
        .then((campaign) => {
            notificationBar.push({
            content: `Fields edited`,
            dismissible: true,
            type: "success",
            });
            navigate("/fields");
        })
        .catch((error) => {
            const message = error?.stack?.message || `Unexpected error: ${error}`;
            notificationBar.push({
            content: message,
            dismissible: true,
            type: "error",
            });
        });
    }, [
        navigate,
        notificationBar,
    ]);

    const definitions: AttributeEditorProps.FieldDefinition<Field>[] = [
      {
        label: 'Name',
        control: (item: Field, index: number) => {
          return (
            <Input
                placeholder="Field name"
                onChange={({ detail }) => setName(detail.value, index)}
                ariaLabel="Field name" 
                value={item.Name} 
                invalid={item.Name == "" || item.Name.includes(" ")}    
                autoFocus={true}            
            />
          );
        },
      },
      {
        label: 'Type',
        control: (item: Field, index: number) =>{
            return (
            <Select
                selectedOption={{ value: item.Type }}
                options={fieldTypes.map((r) => ({ value: r }))}
                placeholder="Select a type"
                onChange={({ detail }) => setType(detail.selectedOption.value!, index)}
                filteringType="auto"
              />
            )
        } 
      },
      {
        label: 'Area',
        control: (item: Field, index: number) =>{
            return (
            <Multiselect
                selectedOptions={item.Area.map((r) => ({ label: r, value: r }))}
                onChange={event => setArea(event.detail.selectedOptions.map(x => x.value!), index)}
                options={[
                    {
                    label: "CBU",
                    value: "CBU"
                    },
                    {
                    label: "VBI",
                    value: "VBI"
                    }
                ]}
                invalid={item.Area.length == 0}
                placeholder="Choose area"
                />
            )
        } 
      }
    ];
    
    const form = (
    <form onSubmit={e => {
            e.preventDefault();
            validateForm()
        }
    } >
      <Form 
        actions={
            <SpaceBetween direction="horizontal" size="xs">
            <Button href="/fields" variant="link">
                Cancel
            </Button>
            <Button variant="primary">Save</Button>
            </SpaceBetween>
        }
        header={<><div style={{ marginTop: 25 }}></div><Header variant="h1">Configure Fields</Header></>}>
        <Container>
            <SpaceBetween direction="vertical" size="l">
            <div style={{ minWidth: 200 }}>
                <h2>Fields</h2>
                <AttributeEditor
                removeButtonText="Remove"
                addButtonText="Add new field"
                empty="No fields found."
                definition={definitions}
                onAddButtonClick={_ => onAddHeaderButtonClickHandler()}
                onRemoveButtonClick={onRemoveHeaderButtonClickHandler}
                items={items}
                />
            </div>
            </SpaceBetween>
        </Container>
      </Form>
      </form>
    );
  
    return (
        <>{globalUser?.Role != 'Admin' ? <Navigate to={"/campaign"} replace /> : spinner ? <Spinner/> : form }</>
    )
  };
  
  export default FieldsPage;
  