import { Flashbar, FlashbarProps } from "@cloudscape-design/components";
import { createContext, useState, useMemo, useContext } from "react";

interface ExtendedMessageDefinition extends FlashbarProps.MessageDefinition {
  duration?: number; // Add a duration property
}
const DEFAULT_DURATION = 10000;

interface INotificationBarContext {
  notifications: ExtendedMessageDefinition[];
  push: (notification: ExtendedMessageDefinition) => void;
  pop: (id: string) => void;
  clear: () => void;
}

const NotificationBarContext = createContext<INotificationBarContext>(
  null as unknown as INotificationBarContext
);

export const NotificationContextProvider: React.FC<
  React.PropsWithChildren<{}>
> = (props) => {
  const [notifications, setNotifications] = useState<
  ExtendedMessageDefinition[]
  >([]);

  const context = useMemo<INotificationBarContext>(
    () => ({
      notifications,
      push: (notification: ExtendedMessageDefinition) => {
        if (!notification.id) notification.id = crypto.randomUUID();
        // Set default duration if not provided
        notification.duration = notification.duration || DEFAULT_DURATION;
        if (notification.dismissible) {
          const onDismiss = notification.onDismiss;
          notification.onDismiss = (event) => {
            setNotifications((current) =>
              current.filter((n) => n.id !== notification.id)
            );
            onDismiss && onDismiss(event);
          };
        }
        setNotifications((current) => [...current, notification]);
        // Remove notification after the specified duration
        if (notification.duration && notification.duration > 0) {
          setTimeout(() => {
            context.pop(notification.id!);
          }, notification.duration);
        }
      },
      pop: (id: string) => {
        setNotifications((current) => current.filter((n) => n.id !== id));
      },
      clear: () => {
        setNotifications([]);
      },
    }),
    [notifications]
  );

  return (
    <NotificationBarContext.Provider value={context}>
      {props.children}
    </NotificationBarContext.Provider>
  );
};

export const useNotificationBar = () => {
  const { notifications, push, pop, clear } = useContext(
    NotificationBarContext
  );
  const component = <Flashbar items={notifications} stackItems />;

  return {
    push,
    pop,
    clear,
    component,
  };
};
