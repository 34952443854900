import { Container } from "@cloudscape-design/components";
import CampaignDetail from "../../components/elements/CampaignDetail";
import { Prop } from "../../openapi-generated/lmpSchemas";

export interface CampaignReviewStepProps {
    name: string,
    type: string,
    area: string,
    enrichFlag: string,
    retention: string,
    targetSys: string,
    dispatchMode: string,
    hashingFlag: string,
    hashingAlg: string,
    encAlg: string,
    fields: Prop[]
}

const CampaignReviewStep = (props: CampaignReviewStepProps) => {
  const content = (
      <CampaignDetail
       name= {props.name}
       type= {props.type}
       area= {props.area}
       enrichFlag= {props.enrichFlag}
       retention= {props.retention}
       targetSys= {props.targetSys}
       dispatchMode= {props.dispatchMode}
       hashingFlag= {props.hashingFlag}
       hashingAlg= {props.hashingAlg}
       encAlg= {props.encAlg}
       fields= {props.fields}
      />
  );

  return {
    data: {},
    title: "Review",
    description: "Review the campaign you are going to create",
    isOptional: false,
    isLoading: false,
    content,
  };
};

export default CampaignReviewStep;
