import { AppLayout } from "@cloudscape-design/components";
import { Outlet } from "react-router-dom";
import MainHeader from "./MainHeader";
import Sidebar from "./Sidebar";
import { ReactNode, useEffect } from "react";
import { useNotificationBar } from "../../hooks/NotificationsBar";
import { useGlobal } from "../../hooks/GlobalContext";
import { Auth } from "aws-amplify";

const Layout: React.FC<
  React.PropsWithChildren<{
    breadcrumb?: ReactNode;
  }>
> = (props) => {
  const notificationBar = useNotificationBar();
  const { globalUser, setGlobalUser } = useGlobal();
  useEffect(() => {
    const getUser = async () => {
      const data = (await Auth.currentSession()).getIdToken().decodePayload();
      const user_info = JSON.parse(data.user_info);
      setGlobalUser({
        Name: user_info.Name,
        Surname: user_info.Surname,
        Role: user_info.Role,
        Area: user_info.Area,
        LastLogin: user_info.LastLogin
      })
    };
    getUser();
  }, []);
  return (
    <>
      <MainHeader />
      {globalUser && <AppLayout
        stickyNotifications
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        breadcrumbs={props.breadcrumb}
        contentType="default"
        navigation={<Sidebar />}
        notifications={notificationBar.component}
        toolsHide
        content={<Outlet />}
      />}
    </>
  );
};

export default Layout;
